import React from "react";
import footerlogo from "../../Assets/BeaconBVILogo.png";
import "./Footer.css";
import { Grid } from "@mui/material";

export default function Footer() {
  return (
    <>
      <div className="footer my">
        <Grid container alignItems="center">
          <Grid item xs={12} md={10} sm={12} className="footer-main">
            <span className="footer-rights" style={{ marginLeft: "25px" }}>
              Hummingbird Health Inc. Copyright &copy;{new Date().getFullYear()}{" "}
              All rights reserved
            </span>
          </Grid>
          {/* <Grid item xs={12} md={5} sm={12}>
            <div className="footer_content">
              <div className="policy">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQs
                </a>
              </div>
              <div className="policy">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  BVI CARE
                </a>
              </div>
              <div className="policy">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  BVI Covid-19
                </a>
              </div>
              <div className="policy">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={12} md={2} sm={12}>
            <span>
              <img className="footer_logo" src={footerlogo} alt="footerlogo" />
            </span>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
