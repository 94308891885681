import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import * as API from "../../Service/API/index.js";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grid,
  InputLabel,
  Input,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  FormControl,
  Box,
  Collapse,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  Select,

} from "@material-ui/core";
import { Divider } from "@mui/material";
import Paper from '@mui/material/Paper';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Switch from "react-switch";
import Closeicon from "../../Assets/close.png";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@material-ui/core/Modal";
import ScrollableFeed from "react-scrollable-feed";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from '@mui/material/InputAdornment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Slider from '../Slider/Slider'
import ReactPanZoom from 'react-image-pan-zoom-rotate'
import PhotoSlider from './SliderComponent/PhotoSlider.js'

function Row({
  dataItems,
  stateSelect,
  countryList,
  labName,
  getlist,
  vaccineNames,
  setloader,
  keyvalue,
  expandedId,
  setExpandedId,
  handleExpandClick,
  dummyStateList,
  setdummyStateList,
  countyList,
  setcountyList,
  raceValues,
  EthnicityList
}) {
  const {
    patientId,
    firstName,
    lastName,
    mobileNumber,
    dob,
    status,
    middleName,
    sendTextToMobile,
    notes,
    formSubmittedOn,
    showRemoveButton
  } = dataItems;

  //Row data States
  const [StatusValue, SetstatusValue] = React.useState("");
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cardUrl: "",
    licenceUrl: "",
  });

  const [sendTextObj, setsendTextObj] = useState({
    sendTextToMobile: sendTextToMobile ? true : false,
  });

  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");
  const [dobDate, setdobDate] = React.useState(null);
  const [note, setNote] = React.useState("");
  const [notesData, setNotesData] = React.useState(notes);
  const [Modalopen, modalsetOpen] = React.useState(false);
  const [Stateloader, setStateloader] = React.useState(false);
  const [countyLoader, setcountyLoader] = React.useState(false);
  const [countyLoaderIndex, setcountyLoaderIndex] = React.useState("");
  const [stateLoaderIndex, setstateLoaderIndex] = React.useState("");
  const [removeDoseIds, setremoveDoseIds] = React.useState([]);
  const [IdentifierList, setIdentifierList] = React.useState(["Driver's License", "Gov't Issued Source", "National Health Insurance", "Passport", "Social Security Number"]);
  const [identifierName, setidentifierName] = useState("");
  const [IdentifierCode, setIdentifierCode] = useState("");
  const [IdentifierType, setIdentifierType] = useState("");
  const [VaccineType, setVaccineType] = useState("COVID-19");
  const [passwordShown, setPasswordShown] = useState(false);
  const vaccinearray = dataItems.vaccinationDoseList.map(
    ({ doseDate, stateList, city, county, injectionSite, doseMeasurement, allergic, cityList, ...rest }) => ({
      doseDate: doseDate ? moment(doseDate) : null,
      stateList: Object.entries(stateList).sort((a, b) =>
        a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
      ),
      cityList: Object.entries(cityList).sort((a, b) =>
        a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
      ),
      city: city ? city : "",
      county: county ? county : "",
      injectionSite: injectionSite ? injectionSite : "",
      doseMeasurement: doseMeasurement ? doseMeasurement : "",
      allergic: allergic ? allergic : "",
      ...rest,
    })
  );

  const [country, setCountry] = useState("");
  const [stateLocation, setstateLocation] = useState("");
  const [county, setcounty] = useState("");

  const [EfirstName, setEfirstName] = useState("");
  const [ElastName, setElastName] = useState("");
  const [Emergencynumber, setEmergencynumber] = useState("");
  const [EmergencydialCode, setEmergencydialCode] = useState("");


  const [changeValue, setchangeValue] = React.useState(
    localStorage.getItem("features")
  );
  // multiple vaxCard URl state
  const [vaxCardurl, setVaxCardurl] = useState(dataItems.vaxCardURLs)
  //Multiple photoId url
  const [photoIdurl, setPhotIdurl] = useState(dataItems.photoIdURLs)

  const [vaccineDoseList, setvaccineDoseList] = React.useState(vaccinearray);

  const [formState, setformState] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    placeOfBirth: "",
    race: "",
    gender: "",
    ethinicity: "",
    residency: "",
  });


  //====Scrolling Ref====//
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const dobRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);
  const vaccinenameRef = useRef(null);
  const vaccinedateRef = useRef(null);
  const lotRef = useRef(null);
  const countryRef = useRef(null);
  const stateRef = useRef(null);
  const countyRef = useRef(null);
  const cityRef = useRef(null);
  const otherLabRef = useRef(null);

  //Error states
  const [firstNameError, setfirstNameError] = React.useState(false);
  const [lastNameError, setlastNameError] = React.useState(false);
  const [dobError, setdobError] = React.useState(false);
  const [mobilenumberError, setmobilenumberError] = React.useState(false);
  const [mobilenumberLengthError, setmobilenumberLengthError] =
    React.useState(false);
  const [emailError, setemailError] = React.useState(false);
  const [emailFormetError, setemailFormetError] = React.useState(false);
  const [vaccinationError, setvaccinationError] = React.useState(false);
  const [vaccinationdateError, setvaccinationdateError] = React.useState(false);
  const [lotError, setlotError] = React.useState(false);
  const [stateError, setstateError] = React.useState(false);
  const [countyError, setcountyError] = React.useState(false);
  const [cityError, setcityError] = React.useState(false);
  const [locationError, setlocationError] = React.useState(false);
  const [OtherlocationError, setOtherlocationError] = React.useState(false);
  const [countryError, setcountryError] = React.useState(false);

  //========Onchange functionalities========//
  const onChange = (e) => {
    let value = e.target.value.replaceAll('"', "'");
    setDetails({ ...details, [e.target.name]: value.replaceAll("''", "'") });
  };

  const formStateonChange = (e) => {
    let value = e.target.value.replaceAll('"', "'");
    setformState({ ...formState, [e.target.name]: value.replaceAll("''", "'") });
  };

  const onChangeSendText = (e) => {
    setsendTextObj({ ...sendTextObj, "sendTextToMobile": e })
  }

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  const handleDobChange = (date) => {
    setdobDate(date);
  };

  const EmergencyFirstNameOnChange = (event) => {
    let value = event.target.value.replaceAll('"', "'");
    setEfirstName(value.replaceAll("''", "'"));
  };

  const EmergencyLastNameOnChange = (event) => {
    let value = event.target.value.replaceAll('"', "'");
    setElastName(value.replaceAll("''", "'"));
  };

  const EmergencyNumberChange = (number, e) => {
    setEmergencynumber(number);
    setEmergencydialCode(e.dialCode);
  };

  const IdentifierListOnChange = (e) => {
    setIdentifierType("");
    setidentifierName(e.target.value);
  }

  const handleTogglePassword = () => setPasswordShown(passwordShown => !passwordShown);

  const countryOnchange = (e) => {
    setCountry(e.target.value);
    countryList.map((item) => {
      if (item.countryCode == e.target.value) {
        let data = {
          countryId: item.id,
        };
        API.GET_BVI_STATELIST(data, (res) => {
          if (res.statusCode === 200) {
            setstateLocation("");
            let allEntries = Object.entries(res.info.states);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            setdummyStateList(sortedEntries);
          }
        });
      }
    });
  }

  //======Get Statelist after accordition======//
  const GetStateList = (code) => {
    if (code !== "") {
      countryList.map((item) => {
        if (item.countryCode == code) {
          let data = {
            countryId: item.id,
          };
          API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              let allEntries = Object.entries(res.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setdummyStateList(sortedEntries);
            }
          });
        }
      });
    } else {
      console.log("Default Statelist")
    }


  }




  const getCountyValues = (StateID) => {
    let data = {
      stateId: StateID,
    };
    API.GET_BVI_CITYLIST(data, (res) => {
      let allEntries = Object.entries(res.info.cities);
      let sortedEntries = allEntries.sort((a, b) =>
        a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
      );
      setcountyList(sortedEntries);
    });
  }

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const stateOnchange = (e) => {
    setstateLocation(e.target.value);
    setcounty("");
    setformState({ ...details, ["city"]: "" });
    dummyStateList.map((item) => {

      if (item[1].stateCode == e.target.value) {
        let data = {
          stateId: item[1].id,
        };
        API.GET_BVI_CITYLIST(data, (res) => {
          let allEntries = Object.entries(res.info.cities);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setcountyList(sortedEntries);

        });
      }
    });
  }

  const CountyOnchange = (e) => {
    setcounty(e.target.value);
  }

  const handleInputChange = (e, index, field) => {
    setfirstNameError(false);
    setlastNameError(false);
    setdobError(false);
    setmobilenumberError(false);
    setmobilenumberLengthError(false);
    setemailError(false);
    setemailFormetError(false);
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);

    if (field === "date") {
      const list = [...vaccineDoseList];
      list[index]["doseDate"] = e === null ? null : moment(e);
      setvaccineDoseList(list);
    } else if (e.target.value && field === "vaccineName") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setvaccineDoseList(list);
    } else if (field === "lot") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...vaccineDoseList];
      list[index][name] = value.replaceAll("''", "'");
      setvaccineDoseList(list);
    } else if (field === "country") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setstateLoaderIndex(`state` + index);
      setvaccineDoseList(list);
      setStateloader(true);
      countryList.map((item) => {
        if (item.countryCode == value) {
          let data = {
            countryId: item.id,
          };
          API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              const list = [...vaccineDoseList];
              list[index]["state"] = "";
              let allEntries = Object.entries(res.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["stateList"] = sortedEntries;
              setStateloader(false);
            }
          });
        }
      });
      setvaccineDoseList(list);
    }
    else if (field === "state") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setcountyLoaderIndex(`county` + index);
      setvaccineDoseList(list);
      setcountyLoader(true);
      // let check1 = e.target.value.split(",");
      list[index]["stateList"].map((item) => {
        if (item[1].stateCode == value) {
          let data = {
            stateId: item[1].id,
          };

          API.GET_BVI_CITYLIST(data, (res) => {
            if (res.statusCode === 200) {
              list[index]["county"] = "";
              list[index]["city"] = "";
              let allEntries = Object.entries(res.info.cities);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["cityList"] = sortedEntries;
              setcountyLoader(false);
            }

          });
        }
      });
    } else if (field === "county") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setvaccineDoseList(list);
    } else if (field === "city") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...vaccineDoseList];
      list[index][name] = value.replaceAll("''", "'");
      setvaccineDoseList(list);
    }
    // else if (e.target.value && field === "location") {
    //   const { name, value } = e.target;
    //   let labidvalue = name.split("?")[0];
    //   let labname = name.split("?")[1];
    //   const lablocation = labName.filter((item) => {
    //     return item.labId == value;
    //   });
    //   const list = [...vaccineDoseList];
    //   list[index][labidvalue] = value;
    //   list[index][labname] = lablocation[0].organizationName;
    //   setvaccineDoseList(list);
    // }
    else if (field === "otherLabName") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...vaccineDoseList];
      list[index][name] = value.replaceAll("''", "'");
      setvaccineDoseList(list);
    } else if (field === "injectionSite" || field === "doseMeasurement" || field === "allergic") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...vaccineDoseList];
      list[index][name] = value.replaceAll("''", "'");
      setvaccineDoseList(list);
    }
  };

  //===Add new dose functionalities===//
  const handleAddClick = (e) => {
    setfirstNameError(false);
    setlastNameError(false);
    setdobError(false);
    setmobilenumberError(false);
    setmobilenumberLengthError(false);
    setemailError(false);
    setemailFormetError(false);
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setcountryError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);

    e.preventDefault();
    let arr = vaccineDoseList.map((item) => {
      return {
        vaccineName: item.vaccineName,
        doseDate: moment(item.doseDate).format("yyyy-MM-DD"),
        location: item.location,
        labId: item.labId,
        lot: item.lot.trim(),
        country: item.country,
        state: item.state,
        county: item.county,
        city: item.city,
        otherLabName: item.location === "Other" ? item.otherLabName.trim() : "",
        vaccinationInfoId: item.vaccinationInfoId,
        stateList: item.stateList,
        cityList: item.cityList,
        newDose: item.newDose,
        vaccinationCategory: VaccineType,
        injectionSite: item.injectionSite,
        doseMeasurement: item.doseMeasurement,
        allergic: item.allergic,
      };
    });

    let filter_data = arr.filter(function (x) {
      if (x.vaccineName != "") {
        setvaccinationError(false);
        return true;
      } else if (x.doseDate != "Invalid date" || x.doseDate != null) {
        setvaccinationdateError(false);
        return true;
      } else if (x.lot.trim() != "") {
        setlotError(false);
        return true;
      } else if (x.country != "") {
        setcountryError(false);
        return true;
      } else if (x.state != "") {
        setstateError(false);
        return true;
      } else if (x.cityList.length && x.county != "") {
        setcountyError(false);
        return true;
      } else if (!x.cityList.length && x.city != "") {
        setcityError(false);
        return true;
      } else if (x.labId != " ") {
        setlocationError(false);
        return true;
      } else if (x.location != "") {
        return true;
      } else if (x.location != "Other" && x.otherLabName.trim() != "") {
        setOtherlocationError(false);
        return true;
      }
    });
    let Checkfilter_data = arr.filter(function (x) {
      if (x.vaccineName == "") {
        setvaccinationError(true);
        return true;
      } else if (x.doseDate === "Invalid date" || x.doseDate === null) {
        setvaccinationdateError(true);
        return true;
      } else if (x.lot.trim() == "") {
        setlotError(true);
        return true;
      } else if (x.country == "") {
        setcountryError(true);
        return true;
      } else if (x.state == "") {
        setstateError(true);
        return true;
      } else if (x.cityList.length && x.county == "") {
        setcountyError(true);
        return true;
      } else if (!x.cityList.length && x.city == "") {
        setcityError(true);
        return true;
      } else if (x.labId == "") {
        setlocationError(true);
        return true;
      } else if (x.location == "") {
        return true;
      } else if (x.location == "Other" && x.otherLabName.trim() == "") {
        setOtherlocationError(true);
        return true;
      }
    });
    if (Checkfilter_data.length === 0) {
      setvaccineDoseList([
        ...arr,
        {
          vaccineName: "",
          doseDate: null,
          lot: "",
          state: "",
          county: "",
          city: "",
          labId: 32,
          location: "Other",
          otherLabName: "",
          vaccinationInfoId: 0,
          country: "",
          stateList: [],
          cityList: [],
          newDose: true,
          vaccinationCategory: "",
          injectionSite: "",
          doseMeasurement: "",
          allergic: ""
        },
      ]);

      setvaccinationError(false);
      setvaccinationdateError(false);
      setlotError(false);
      setcountryError(false);
      setstateError(false);
      setcountyError(false);
      setcityError(false);
      setlocationError(false);
      setOtherlocationError(false);
    }
  };

  //------onClick Form Update--------//
  const updateData = (e, patientId) => {
    e.preventDefault();
    setfirstNameError(false);
    setlastNameError(false);
    setdobError(false);
    setmobilenumberError(false);
    setmobilenumberLengthError(false);
    setemailError(false);
    setemailFormetError(false);
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setcountryError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);

    let arr = vaccineDoseList.map((item) => {
      return {
        vaccinationType: item.vaccineName,
        date: moment(item.doseDate).format("yyyy-MM-DD"),
        location: item.location,
        labId: item.labId,
        lot: item.lot.trim(),
        country: item.country,
        state: item.state,
        county: item.county,
        city: item.city,
        cityList: item.cityList,
        otherLabName: item.location === "Other" ? item.otherLabName.trim() : "",
        vaxInfoId: item.vaccinationInfoId,
        vaccinationCategory: VaccineType,
        injectionSite: item.injectionSite,
        doseMeasurement: item.doseMeasurement,
        allergic: item.allergic,
      };
    });

    if (arr.length === 0) {
      toast.error("Please add atleast one dose before save");
    }
    let filter_data = arr.filter(function (x) {
      if (x.vaccinationType != "") {
        return true;
      } else if (x.date != "Invalid date" || x.date != null) {
        return true;
      } else if (x.lot.trim() != "") {
        return true;
      } else if (x.country != "") {
        setcountryError(false);
        return true;
      } else if (x.state != "") {
        return true;
      } else if (x.cityList.length && x.county != "") {
        setcountyError(false);
        return true;
      } else if (!x.cityList.length && x.city != "") {
        setcityError(false);
        return true;
      } else if (x.labId != " ") {
        return true;
      } else if (x.location != "") {
        return true;
      } else if (x.location != "Other" && x.otherLabName.trim() != "") {
        return true;
      } else if (x.vaccinationCategory != "") {
        return true;
      } else if (x.injectionSite.trim() != "") {
        return true;
      } else if (x.doseMeasurement.trim() != "") {
        return true;
      } else if (x.allergic.trim() != "") {
        return true;
      }

    });

    const newFilterArr = filter_data.map(({ cityList, ...rest }) => {
      return rest;
    });

    let Checkfilter_data = arr.filter(function (x) {
      if (x.vaccinationType == "") {
        return true;
      } else if (x.date === "Invalid date" || x.date === null) {
        return true;
      } else if (x.lot.trim() == "") {
        return true;
      } else if (x.country == "") {
        setcountryError(true);
        return true;
      } else if (x.state == "") {
        return true;
      } else if (x.cityList.length && x.county == "") {
        setcountyError(true);
        return true;
      } else if (!x.cityList.length && x.city == "") {
        setcityError(true);
        return true;
      } else if (x.labId == "") {
        return true;
      } else if (x.location == "") {
        return true;
      } else if (x.location == "Other" && x.otherLabName.trim() == "") {
        return true;
      }
    });

    if (details.firstName.trim() === "") {
      setfirstNameError(true);
      firstnameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (details.lastName.trim() === "") {
      setlastNameError(true);
      lastnameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (dobDate === "Invalid date" || dobDate === null) {
      setdobError(true);
      dobRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (details.email.trim() === "") {
      setemailError(true);
      emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (details.email.trim() !== "N/A" && (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(details.email.trim()) &&
      details.email.trim() != "")) {
      setemailFormetError(true);
      emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      setmobilenumberError(true);
      mobileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (newnumber.slice(dialCode.length).length < 8) {
      setmobilenumberLengthError(true);
      mobileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (vaccineDoseList.length) {
      for (let i = 0; i < vaccineDoseList.length; i++) {
        if (vaccineDoseList[i].vaccineName === "") {
          setvaccinationError(true);
          if (vaccinenameRef && vaccinenameRef.current) {
            vaccinenameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (
          vaccineDoseList[i].doseDate === "Invalid date" ||
          vaccineDoseList[i].doseDate === null
        ) {
          setvaccinationdateError(true);
          if (vaccinedateRef && vaccinedateRef.current) {
            vaccinedateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].lot.trim() === "") {
          setlotError(true);
          if (lotRef && lotRef.current) {
            lotRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
        else if (vaccineDoseList[i].country === "") {
          setcountryError(true);
          if (countryRef && countryRef.current) {
            countryRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
        else if (vaccineDoseList[i].state === "") {
          setstateError(true);
          if (stateRef && stateRef.current) {
            stateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].cityList.length && vaccineDoseList[i].county === "") {
          setcountyError(true);
          if (countyRef && countyRef.current) {
            countyRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (!vaccineDoseList[i].cityList.length && vaccineDoseList[i].city === "") {
          setcityError(true);
          if (cityRef && cityRef.current) {
            cityRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].labId === "") {
          setlocationError(true);
        } else if (
          vaccineDoseList[i].labId === 32 &&
          vaccineDoseList[i].otherLabName.trim() === ""
        ) {
          setOtherlocationError(true);
          if (otherLabRef && otherLabRef.current) {
            otherLabRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
      if (Checkfilter_data.length === 0) {
        console.log(">>>>>>> API call >>>>>>>>>>");
        setloader(true);
        let data = {
          patientId: patientId,
          firstName: details.firstName,
          lastName: details.lastName,
          middleName: middleName,
          email: details.email,
          dob: moment(dobDate).format("yyyy-MM-DD"),
          mobileNumber: newnumber.includes("+") ? newnumber : "+" + newnumber,
          status,
          sendTextToMobile,
          vaxInfos: newFilterArr,
          idsToDelete: removeDoseIds,
          gender: formState.gender,
          race: formState.race,
          ethinicity: formState.ethinicity,
          country: country,
          state: stateLocation,
          addressOne: formState.addressOne,
          addressTwo: formState.addressTwo,
          county: county,
          city: formState.city ? formState.city.trim() : "",
          identifier: identifierName,
          identifierCode: IdentifierCode ? IdentifierCode.trim() : "",
          identifierOtherValidSource: IdentifierType ? IdentifierType.trim() : "",
          placeOfBirth: formState.placeOfBirth ? formState.placeOfBirth.trim() : "",
          residency: formState.residency,
          emergencyContactFirstName: EfirstName,
          emergencyContactLastName: ElastName,
          emergencyContactPhoneNumber: Emergencynumber == "" ? "" : Emergencynumber.includes("+") ? Emergencynumber : "+" + Emergencynumber,
        };
        console.log("data", data)
        API.EXIST_UPDATE_FORM_API(data, (res) => {
          if (res.statusCode === 200) {
            toast.success("Patient details updated successfully");
            setExpandedId(-1);
            window.scrollTo(0, 0);
            getlist();
          } else {
            setloader(false);
            toast.error(res.info);
          }
        });
      }
    }
  };

  //------onSelect--------//
  const onSelect = (id, keyvalue) => {
    handleExpandClick(keyvalue);
    if (id) {
      setDetails({
        ...details,
        ...dataItems,
      });
      setformState({
        ...formState,
        ...dataItems,
      });
      setremoveDoseIds([]);
      setvaccineDoseList(vaccinearray);
      setNote("");
      setNumber(dataItems.mobileNumber ? dataItems.mobileNumber : "");
      setdobDate(moment(dataItems.dob));
      setidentifierName(dataItems.identifier ? dataItems.identifier : "");
      setIdentifierCode(dataItems.identifierCode ? dataItems.identifierCode : "");
      setIdentifierType(dataItems.identifierOtherValidSource ? dataItems.identifierOtherValidSource : "");
      setCountry(dataItems.country ? dataItems.country : "");
      setstateLocation(dataItems.state ? dataItems.state : "");
      GetStateList(dataItems.country ? dataItems.country : "");
      getCountyValues(dataItems.stateID ? dataItems.stateID : 4967);
      setcounty(dataItems.county ? dataItems.county : "");
      setEfirstName(dataItems.emergencyContactFirstName ? dataItems.emergencyContactFirstName : "");
      setElastName(dataItems.emergencyContactLastName ? dataItems.emergencyContactLastName : "");
      setEmergencynumber(dataItems.emergencyContactPhoneNumber ? dataItems.emergencyContactPhoneNumber : "");
    }
  };

  //------onClick ADD--------//
  const AddNote = (e, id) => {
    e.preventDefault();
    const newItem = {
      patientId: id,
      notes: note.trim(),
    };

    if (newItem.notes != "") {
      API.EXIST_ADD_NOTE_API(newItem, (res) => {
        if (res.statusCode === 200) {
          toast.success(res.info);
        } else {
          toast.error(res.info);
        }
      });
      const newData = [...notesData, newItem];
      setNotesData(newData);
      setNote("");
    } else {
      toast.error("Please enter the note");
    }
  };

  const handleRemoveClick = (index, newDose) => {
    if (newDose == true) {
      confirmAlert({
        title: 'Delete Dose',
        message: `Are you sure you want to delete this dose?`,
        buttons: [
          {
            label: 'Confirm',
            onClick: () => RemoveDummyDoseconfirmfun(index)
          },
          {
            label: 'Cancel',
            onClick: () => Dosecancelfun()
          }
        ],
      })
    } else {
      confirmAlert({
        title: 'Delete Dose',
        message: `Are you sure you want to delete this dose?`,
        buttons: [
          {
            label: 'Confirm',
            onClick: () => RemoveDBDoseconfirmfun(newDose, index)
          },
          {
            label: 'Cancel',
            onClick: () => Dosecancelfun()
          }
        ],
      })
    }
  };

  const RemoveDummyDoseconfirmfun = (index) => {
    const list = [...vaccineDoseList];
    list.splice(index, 1);
    setvaccineDoseList(list);
  }

  const Dosecancelfun = () => {
    console.log("cancel clicked")
  }

  const RemoveDBDoseconfirmfun = (newDoseVal, formIDvalue) => {
    console.log("===API CALL CAN RUN===");
    let data = {
      type: "existing",
      id: newDoseVal
    };
    const itratevalue = [...removeDoseIds, newDoseVal];
    setremoveDoseIds(itratevalue);
    console.log('itratevalue', itratevalue);

    const list = [...vaccineDoseList];
    list.splice(formIDvalue, 1);
    setvaccineDoseList(list);

    // API.DELETE_DOSE(data, (res) => {
    //   if (res.statusCode === 200) {
    //     toast.success("Dose deleted successfully");
    //     window.scrollTo(0, 0);
    //     getlist();      
    //   } else {
    //     toast.error(res.info);
    //   }
    // });
  }

  //===Status update functionalities===//
  const alertbtn = (value) => {
    confirmAlert({
      title: 'Confirm to status approval',
      message: `Are you sure?`,
      childrenElement: () => <div>You want to change the status as <span className="Status_name">{value}</span></div>,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => confirmfun(value)
        },
        {
          label: 'Cancel',
          onClick: () => cancelfun()
        }
      ],
    })
  };

  const confirmfun = (val) => {
    setloader(true);
    SetstatusValue(val);
    let data = {
      status: val,
      patientId: patientId,
      BVICode: "BVI",
    };
    console.log("data", data)
    API.EXIST_UPDATE_STATUS_API(data, (res) => {
      if (res.statusCode === 200) {
        setExpandedId(-1);
        getlist();
        toast.success("Status updated successfully");
      }
      else {
        setExpandedId(-1);
        getlist();
        toast.error(res.errorMessage);
      }
    });

  }

  const cancelfun = () => {
    console.log("cancel clicked")
  }

  const downloadPDF = (e, patientId) => {
    e.preventDefault();

    let data = {
      patientId: patientId
    };
    API.DOWNLOAD_PDF_FILE(data, (res) => {

      if (res.statusCode === 200) {
        setloader(false);
        let downloadURL = res.info;
        window.open(downloadURL, '_blank');
        // window.location.href = downloadURL
      }


    });

  }


  const resendNotifiy = (e, patientId) => {
    e.preventDefault();
    setloader(true);
    let data = {
      patientId: patientId
    };
    API.RESEND_NOTIFICATION(data, (res) => {

      if (res.statusCode === 200) {
        setloader(false);
        setExpandedId(-1);
        window.scrollTo(0, 0);
        toast.success("PolarisKey Successfully Resent");
      } else {
        setloader(false);
        setExpandedId(-1);
        window.scrollTo(0, 0);
        toast.error("Polariskey Resent failed");
      }
    });


  }


  const handleStatus = (e) => {
    if (e.target.value !== "") {
      alertbtn(e.target.value);
    }
  };

  const [getImg, setImg] = React.useState("");
  const handleOpen = (imgURL) => {
    if (imgURL != "") {
      setImg(imgURL);
      modalsetOpen(true);
    }
  };
  const handleClose = () => {
    modalsetOpen(false);
  };

  const body = (
    <div
      style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      className="paperModal"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <div className="closeHolder" onClick={handleClose}>
        <img src={Closeicon} style={{ width: "30px" }} alt="close-icon" />
      </div>
      {getImg.split(".").pop().split(/\#|\?/)[0].toLowerCase() === "pdf" ? (
        <div className="pdfViewModal">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div>
              <Viewer defaultScale={0.5} fileUrl={getImg} />
            </div>
          </Worker>
        </div>
      ) : (
        <div className="modal_image_preview">
          <img
            src={getImg}
            alt="modal-view"
            draggable="false"
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <TableRow className="table_row">
        <TableCell
          onClick={() => onSelect(patientId, keyvalue)}
          style={{ cursor: "pointer" }}
        >
          {(expandedId === keyvalue) == false ? (
            <NavigateNextIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </TableCell>
        <TableCell
          className="table_row_size"
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {formSubmittedOn ? moment(formSubmittedOn).format("MM-DD-yyyy") : ""}
        </TableCell>

        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          component="th"
          scope="row"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {firstName}
        </TableCell>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {lastName}
        </TableCell>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {moment(dob).format("MM-DD-yyyy")}
        </TableCell>

        <TableCell className="table_row_size" align="center">
          <FormControl fullWidth variant="outlined" className="form-dropdown">
            <Select
              native
              disabled={changeValue.includes("Approval Module(edit)") ? false : true}
              value={StatusValue ? StatusValue : status}
              onChange={handleStatus}
              className="select_res"
              inputProps={{
                name: "Status",
              }}
            >
              <option aria-label="None" value="" />
              <optgroup label="- Approval Statuses -"></optgroup>
              <option value={"Document Approval - L1"}>Document Approval - L1</option>
              <option value={"Registry Approval - L2"}>
                Registry Approval - L2
              </option>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expandedId === keyvalue} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <form autoComplete="off">
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sm={12} ref={firstnameRef}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="First name *"
                        type="text"
                        value={details.firstName}
                        name="firstName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                        className="form_textfield"
                      />
                      {firstNameError === true &&
                        details.firstName.trim() === "" ? (
                        <div className="errorText mt">
                          Please enter the firstname
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} ref={lastnameRef}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Last name *"
                        type="text"
                        value={details.lastName}
                        name="lastName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                        className="form_textfield"
                      />

                      {lastNameError === true &&
                        details.lastName.trim() === "" ? (
                        <div className="errorText mt">
                          Please enter the lastName
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} ref={dobRef}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className=" form-dropdown date_search_field"
                          style={{
                            width: "100%",
                          }}
                          name="dob"
                          clearable
                          value={dobDate}
                          onChange={(date) => handleDobChange(date)}
                          format="MM/dd/yyyy"
                          label="DOB (MM/DD/YYYY)"
                          maxDate={new Date()}
                          required
                          autoOk
                        />
                      </MuiPickersUtilsProvider>

                      {dobError === true && dobDate === null ? (
                        <div className="errorText mt">
                          Please enter the date of birth
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12} md={4} sm={12} ref={emailRef}>
                      <TextField
                        style={{ marginTop: "10px" }}
                        fullWidth
                        variant="standard"
                        label="Email *"
                        type="text"
                        value={details.email}
                        name="email"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                        className="form_textfield"
                      />
                      {emailError === true && details.email.trim() === "" ? (
                        <div className="errorText mt">
                          Please enter the email
                        </div>
                      ) : null}
                      {emailFormetError === true ? (
                        <div className="errorText mt">
                          Please enter the valid email
                        </div>
                      ) : null}
                    </Grid>
                    <Grid className="Addform_phonenumber" item xs={12} md={4} sm={12} ref={mobileRef}>
                      <InputLabel
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          paddingBottom: "0.3em",
                          fontSize: "13px",
                        }}
                      >
                        Mobile number *
                      </InputLabel>
                      <PhoneInput
                        country={"us"}
                        // onlyCountries={["us"]}
                        // countryCodeEditable={false}
                        required
                        name="mobileNumber"
                        // disableDropdown={true}
                        onChange={mobileNumberChange}
                        value={newnumber}
                      />
                      {mobilenumberError === true ? (
                        <div className="errorText mt">
                          Please enter the Mobile Number
                        </div>
                      ) : null}
                      {mobilenumberLengthError === true ? (
                        <div className="errorText mt">
                          Please enter the full digit mobile number
                        </div>
                      ) : null}
                    </Grid>

                    <Grid className="d-flex" style={{ justifyContent: "flex-start" }} item xs={12} md={4} sm={12}>
                      <Switch
                        checked={sendTextObj.sendTextToMobile}
                        onChange={onChangeSendText}
                        name="sendTextToMobile"
                      />
                      <span
                        style={{
                          color: "#808080",
                          marginLeft: "10px",
                          fontSize: "15px",
                        }}
                      >
                        Can this number receive text messages?
                      </span>
                    </Grid>
                    {details.addedInHSA == "Y" && details.cardUrl == "" && details.licenceUrl == "" ? <div></div> : (<><Grid item xs={12} md={6} sm={12} className="my">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <div className="span_text">Photo ID:</div>
                        <div>
                          {photoIdurl.length ? (
                            <PhotoSlider data={photoIdurl} getlist={getlist} />
                          ) : vaxCardurl.length ? (
                            <div
                              style={{
                                height: '120px',
                                display: 'table-cell',
                                verticalAlign: 'middle',
                              }}
                            >
                              Auto approved. Photo not required.
                            </div>
                          ) : (
                            <div>Auto approved. Photo not required.</div>
                          )}
                          {/* {details.licenceUrl ? (
                            ['jpeg', 'png', 'jpg', 'heif', 'pdf'].includes(
                              details.licenceUrl
                                .split('.')
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase(),
                            ) ? (
                              details.licenceUrl
                                .split('.')
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === 'pdf' ? (
                                <div>
                                  <Button
                                    style={{
                                      background: '#f07167',
                                      color: '#fff',
                                      fontWeight: 'bold',
                                      padding: '3px 8px',
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.licenceUrl)
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  className="preview-img"
                                  alt="preview-img"
                                  src={details.licenceUrl}
                                  onClick={(e) => {
                                    handleOpen(details.licenceUrl)
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                              )
                            ) : (
                              // <a
                              //   style={{ color: "#f07167" }}
                              //   href={details.licenceUrl}
                              //   target="_blank"
                              // >
                              //   Open the file
                              // </a>
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div
                              style={{
                                height: '120px',
                                display: 'table-cell',
                                verticalAlign: 'middle',
                              }}
                            >
                              Auto approved. Photo not required.
                            </div>
                          )} */}
                        </div>
                      </div>
                    </Grid>
                      <Grid item xs={12} md={6} sm={12} className="my">
                        <div
                          className="d-flex"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div className="span_text">VAX Record Photo:</div>
                          <div>
                            {vaxCardurl.length ? (
                              <Slider data={vaxCardurl} getlist={getlist} />
                            ) : photoIdurl.length ? (
                              <div
                                style={{
                                  height: '120px',
                                  display: 'table-cell',
                                  verticalAlign: 'middle',
                                }}
                              >
                                Auto approved. Photo not required.
                              </div>
                            ) : (
                              <div>Auto approved. Photo not required.</div>
                            )}
                            {/* {details.cardUrl ? (
                            ["jpeg", "png", "jpg", "heif", "pdf"].includes(
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase()
                            ) ? (
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === "pdf" ? (
                                <div>
                                  <Button
                                    style={{
                                      background: "#f07167",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      padding: "3px 8px",
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.cardUrl);
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  className="preview-img"
                                  alt="preview-img"
                                  src={details.cardUrl}
                                  onClick={(e) => {
                                    handleOpen(details.cardUrl);
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )
                            ) : (
                              // <a
                              //   style={{ color: "#f07167" }}
                              //   href={details.cardUrl}
                              //   target="_blank"
                              // >
                              //   Open the file
                              // </a>
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div>Auto approved. Photo not required.</div>
                          )} */}
                          </div>
                        </div>
                      </Grid></>)}

                    <Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown"
                      >
                        <InputLabel>
                          Identifier
                        </InputLabel>
                        <Select
                          label="Identifier"
                          name="IdentifierName"
                          value={identifierName}
                          onChange={IdentifierListOnChange}
                        >
                          {IdentifierList.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <TextField
                        disabled={changeValue.includes("View SSN") ? false : true}
                        fullWidth
                        variant="standard"
                        label="Identifier code"
                        type={passwordShown && changeValue.includes("View SSN") ? "text" : "password"}
                        value={IdentifierCode}
                        name="IdentifierCode"
                        onChange={(e) => setIdentifierCode(e.target.value)}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton
                              disableRipple
                              edge="end" onClick={handleTogglePassword}>
                              {passwordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>,
                        }}
                        className="form_textfield"
                      />
                    </Grid>
                    {identifierName == "Gov't Issued Source" ? (<>
                      <Grid item xs={12} md={4} sm={12}>
                        <TextField
                          variant="standard"
                          className="form_textfield"
                          fullWidth
                          id="outlined-search"
                          label="Identifier Type"
                          type="text"
                          name="IdentiferType"
                          inputProps={{ maxLength: 30 }}
                          value={IdentifierType}
                          autoComplete="off"
                          onChange={(e) => setIdentifierType(e.target.value)}
                        />
                      </Grid>
                    </>) : <Grid item xs={12} md={4} sm={12} className="gridHide"></Grid>}

                    <Grid item xs={12} md={4} sm={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Address 1"
                        type="text"
                        name="addressOne"
                        className="form_textfield"
                        inputProps={{ maxLength: 30 }}
                        value={formState.addressOne}
                        autoComplete="off"
                        onChange={formStateonChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        className="form_textfield"
                        label="Address 2"
                        type="text"
                        name="addressTwo"
                        inputProps={{ maxLength: 30 }}
                        value={formState.addressTwo}
                        autoComplete="off"
                        onChange={formStateonChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} className="gridHide"></Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown"
                      >
                        <InputLabel>Country</InputLabel>
                        <Select
                          label="Country"
                          className="Addform_menuitems"
                          name="country"
                          value={country}
                          onChange={countryOnchange}
                        >
                          {countryList.map((item) => (
                            <MenuItem key={item.id} value={item.countryCode}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown">
                        <InputLabel>Location</InputLabel>
                        <Select
                          label="Location"
                          className="Addform_menuitems"
                          name="stateLocation"
                          value={stateLocation}
                          onChange={stateOnchange}
                        >
                          {dummyStateList.map((item) => (
                            <MenuItem key={item[1].stateCode} value={item[1].stateCode}>
                              {item[0]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} className="gridHide"></Grid>
                    {countyList.length ? (<Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown">
                        <InputLabel>County/district</InputLabel>
                        <Select
                          label="County/district"
                          name="county"
                          value={county}
                          onChange={CountyOnchange}
                        >
                          {countyList.map((item) => (
                            <MenuItem key={item[1].id} value={item[0]}>{item[0]}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>) : (<Grid item xs={12} md={4} sm={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        id="outlined-search"
                        label="City"
                        inputProps={{ maxLength: 30 }}
                        autoComplete="off"
                        type="text"
                        name="city"
                        className="form_textfield"
                        value={formState.city}
                        onChange={formStateonChange}
                      />
                    </Grid>)}
                    <Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        className="form-dropdown"
                        fullWidth
                        variant="standard">
                        <InputLabel>
                          Residency status
                        </InputLabel>
                        <Select
                          label="Residency status"
                          name="residency"
                          value={formState.residency}
                          onChange={formStateonChange}
                        >
                          <MenuItem value={"BVIslander/Belonger"}>
                            BVIslander/Belonger
                          </MenuItem>
                          <MenuItem value={"BVI Resident"}>BVI Resident</MenuItem>
                          <MenuItem value={"Tourist"}>Tourist</MenuItem>
                          <MenuItem value={"Work Permit Holder/Exempt"}>Work Permit Holder/Exempt</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                  </Grid>
                  <Divider style={{ marginTop: "24px", width: "100%" }} />
                  <div className="my" style={{ fontSize: "16px", fontWeight: "400", width: "100%" }}>Emergency Contact</div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sm={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        className="form_textfield"
                        label="First name"
                        type="text"
                        name="EfirstName"
                        inputProps={{ maxLength: 30 }}
                        value={EfirstName}
                        autoComplete="off"
                        onChange={EmergencyFirstNameOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        className="form_textfield"
                        label="Last name"
                        type="text"
                        name="ElastName"
                        inputProps={{ maxLength: 30 }}
                        value={ElastName}
                        autoComplete="off"
                        onChange={EmergencyLastNameOnChange}
                      />

                    </Grid>
                    <Grid style={{ marginTop: "5px" }} className="Addform_phonenumber" item xs={12} md={4} sm={12}>
                      <PhoneInput
                        country={"us"}
                        required
                        name="Emergencynumber"
                        onChange={EmergencyNumberChange}
                        value={Emergencynumber}
                      />

                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: "24px", width: "100%" }} />
                  <div className="my" style={{ fontSize: "16px", fontWeight: "400", width: "100%" }}>Additional Demographics</div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sm={12}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Place of birth"
                        type="text"
                        className="form_textfield"
                        name="placeOfBirth"
                        inputProps={{ maxLength: 30 }}
                        value={formState.placeOfBirth}
                        autoComplete="off"
                        onChange={formStateonChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} className="gridHide"></Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown"
                      >
                        <InputLabel>Gender</InputLabel>
                        <Select
                          label="Gender"
                          name="gender"
                          value={formState.gender}
                          onChange={formStateonChange}
                        >
                          <MenuItem value={"Female"}>Female</MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value="Ambiguous">Non-binary</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown"
                      >
                        <InputLabel>Race</InputLabel>
                        <Select
                          label="Race"
                          name="race"
                          value={formState.race}
                          onChange={formStateonChange}
                        >
                          {raceValues.map((item) => (
                            <MenuItem key={item.raceName} value={item.raceName}>
                              {item.raceName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown">
                        <InputLabel>Ethnicity</InputLabel>
                        <Select
                          aria-label="ethnicity"
                          label="Ethnicity"
                          name="ethinicity"
                          value={formState.ethinicity}
                          onChange={formStateonChange}
                        >
                          {EthnicityList.map((item) => (
                            <MenuItem key={item.ethinicityName} value={item.ethinicityName}>
                              {item.ethinicityName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: "24px", width: "100%" }} />
                  <Grid container spacing={2}>
                    <Grid style={{ marginTop: "15px" }} item xs={12} md={3} sm={12}>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="form-dropdown">
                        <InputLabel>
                          Vaccine type
                        </InputLabel>
                        <Select
                          label="Vaccine type"
                          name="VaccineType"
                          value={VaccineType}
                          onChange={(e) => setVaccineType(e.target.value)}
                        >
                          <MenuItem key={"COVID-19"} value={"COVID-19"}>
                            COVID-19
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container className="mt" spacing={2}>
                    {vaccineDoseList.map((dose, i) => {
                      return (
                        <>
                          <Paper elevation={4} style={{ width: "100%", padding: "20px", margin: "5px 0px" }}>
                            <Grid container spacing={3}>
                              {showRemoveButton == true || dose.newDose == true ?
                                <Grid item xs={12} md={12} sm={12} style={{ padding: "0px" }}>
                                  {changeValue.includes("Approval Module(view)") ? null : (<div className="removeDose">
                                    <span className="removebtn"
                                      onClick={() => handleRemoveClick(i, dose.newDose ? dose.newDose : dose.vaccinationInfoId, keyvalue)}
                                    >
                                      <DeleteIcon
                                        style={{ width: "20px", marginRight: "5px" }}
                                      />Remove
                                    </span>
                                  </div>)}
                                </Grid> : null}

                              <Grid item xs={12} md={6} sm={12} ref={vaccinenameRef}>
                                <FormControl
                                  fullWidth
                                  variant="standard"
                                  className="form-dropdown"
                                >
                                  <InputLabel>
                                    <span style={{ marginRight: "6px" }}>
                                      {i + 1}
                                      {i + 1 == 1
                                        ? "st"
                                        : i + 1 == 2
                                          ? "nd"
                                          : i + 1 == 3
                                            ? "rd"
                                            : "th"}
                                    </span>Vaccination Name *
                                  </InputLabel>
                                  <Select
                                    name="vaccineName"
                                    value={dose.vaccineName}
                                    onChange={(e) =>
                                      handleInputChange(e, i, "vaccineName")
                                    }
                                    required
                                  >
                                    {vaccineNames.map((item) => (
                                      <MenuItem
                                        key={item.vaccineName}
                                        value={item.vaccineName}
                                      >
                                        {item.vaccineName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                {vaccinationError === true &&
                                  dose.vaccineName === "" ? (
                                  <div className="errorText mt">
                                    Please select Vaccination name
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12} ref={vaccinedateRef}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    className="form-dropdown date_search_field"
                                    style={{
                                      width: "100%",
                                    }}
                                    clearable
                                    name="date"
                                    value={dose.doseDate}
                                    onChange={(e) =>
                                      handleInputChange(e, i, "date")
                                    }
                                    format="MM/dd/yyyy"
                                    label="Vaccination date (MM/DD/YYYY)"
                                    maxDate={new Date()}
                                    required
                                    autoOk
                                  />
                                </MuiPickersUtilsProvider>
                                {vaccinationdateError === true &&
                                  dose.doseDate === null ? (
                                  <div className="errorText mt">
                                    Please select Vaccination date
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12} ref={lotRef}>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  label="Lot # *"
                                  type="text"
                                  value={dose.lot}
                                  name="lot"
                                  onChange={(e) => handleInputChange(e, i, "lot")}
                                  className="form_textfield"
                                />
                                {lotError === true && dose.lot.trim() === "" ? (
                                  <div className="errorText mt">
                                    Please enter the LOT no
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12} ref={countryRef}>
                                <FormControl
                                  className="form-dropdown"
                                  fullWidth
                                  variant="standard"
                                >
                                  <InputLabel>Country *</InputLabel>
                                  <Select
                                    name="country"
                                    value={dose.country}
                                    onChange={(e) =>
                                      handleInputChange(e, i, "country")
                                    }
                                    required
                                  >
                                    {countryList.map((item) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.countryCode}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {countryError === true && dose.country === "" ? (
                                  <div className="errorText mt">
                                    Please select the Country
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                              {`state` + i == stateLoaderIndex && Stateloader == true ? (
                                <Grid item xs={12} md={4} sm={12} className="stateLoader">
                                  <div class="loader1">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                </Grid>
                              ) : (
                                <Grid item xs={12} md={4} sm={12} ref={stateRef}>
                                  <FormControl
                                    className="form-dropdown"
                                    fullWidth
                                    variant="standard"
                                  >
                                    <InputLabel>Location *</InputLabel>
                                    <Select
                                      name="state"
                                      value={dose.state}
                                      onChange={(e) =>
                                        handleInputChange(e, i, "state")
                                      }
                                      required
                                    >
                                      {dose.stateList &&
                                        dose.stateList.map((item) => (
                                          <MenuItem
                                            value={
                                              item[1].stateCode
                                            }
                                          >
                                            {item[0]}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                  {stateError === true && dose.state === "" ? (
                                    <div className="errorText mt">
                                      Please select the location
                                    </div>
                                  ) : null}
                                </Grid>
                              )}
                              {`county` + i == countyLoaderIndex && countyLoader == true ? (
                                <Grid item xs={12} md={4} sm={12} className="stateLoader">
                                  <div class="loader1">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                </Grid>

                              ) : (<>
                                {dose.cityList && dose.cityList.length ? <><Grid item xs={12} md={4} sm={12} ref={countyRef}>
                                  <FormControl
                                    className="form-dropdown"
                                    fullWidth
                                    variant="standard"
                                  >
                                    <InputLabel>County/District *</InputLabel>
                                    <Select
                                      name="county"
                                      value={dose.county}
                                      onChange={(e) => handleInputChange(e, i, "county")}
                                      required
                                    >
                                      {dose.cityList && dose.cityList.map((item) => (
                                        <MenuItem value={item[0]}>{item[0]}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {countyError === true && dose.county === "" ? (
                                    <div className="errorText">
                                      Please select the County
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Grid></> : <Grid item xs={12} md={4} sm={12} ref={cityRef}>
                                  <TextField
                                    fullWidth
                                    className="form_textfield"
                                    label="City/Estate *"
                                    variant="standard"
                                    name="city"
                                    inputProps={{ maxLength: 40, autoComplete: "none" }}
                                    value={dose.city}
                                    onChange={(e) => handleInputChange(e, i, "city")}
                                  />
                                  {cityError === true && dose.city === "" ? (
                                    <div className="errorText">
                                      Please enter the City
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Grid>}</>
                              )
                              }

                              {/* <Grid item xs={12} md={6} sm={12}>
                            <FormControl
                              variant="standard"
                              className="form-dropdown"
                              fullWidth
                            >
                              <InputLabel>Site *</InputLabel>
                              <Select
                                name="labId?location"
                                value={dose.labId}
                                onChange={(e) =>
                                  handleInputChange(e, i, "location")
                                }
                                required
                              >
                                {labName.map((item) => (
                                  <MenuItem key={item.labId} value={item.labId}>
                                    {item.organizationName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {locationError === true && dose.labId === "" ? (
                              <div className="errorText mt">
                                Please select the site.
                              </div>
                            ) : null}
                          </Grid> */}
                              {dose.labId === 32 && (
                                <Grid item xs={12} md={4} sm={12} ref={otherLabRef}>
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    className="form_textfield"
                                    value={dose.otherLabName}
                                    name="otherLabName"
                                    label="Please specify site location *"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "otherLabName")
                                    }
                                    inputProps={{
                                      maxLength: 30,
                                      autoComplete: "none",
                                    }}
                                  />

                                  {OtherlocationError === true &&
                                    dose.otherLabName.trim() === "" ? (
                                    <div className="errorText mt">
                                      Please enter the site name
                                    </div>
                                  ) : null}
                                </Grid>
                              )}
                              <Grid item xs={12} md={8} sm={12}>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  className="form_textfield"
                                  label="Injection site"
                                  type="text"
                                  name="injectionSite"
                                  inputProps={{ maxLength: 30 }}
                                  value={dose.injectionSite}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    handleInputChange(e, i, "injectionSite")
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sm={12}>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  className="form_textfield"
                                  label="Dose measurement"
                                  type="text"
                                  name="doseMeasurement"
                                  inputProps={{ maxLength: 30 }}
                                  value={dose.doseMeasurement}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    handleInputChange(e, i, "doseMeasurement")
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={12} sm={12}>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  className="form_textfield"
                                  label="Severe allergic reaction to a vaccination or injection?"
                                  type="text"
                                  name="allergic"
                                  inputProps={{ maxLength: 150 }}
                                  value={dose.allergic}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    handleInputChange(e, i, "allergic")
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={6} sm={12}>
                                <span className="span_text">Site phone:</span>
                                {dose.sitePhoneNumber}
                              </Grid>

                            </Grid>
                          </Paper>
                        </>
                      );
                    })}
                    <Grid item xs={12} md={6} sm={12}>
                      <span className="span_text">Notes:</span>
                      <FormControl fullWidth className="form-dropdown">
                        <InputLabel htmlFor="my-input">Add notes</InputLabel>
                        <Input
                          label="Add notes"
                          type="text"
                          value={note}
                          name="Add notes"
                          onChange={handleNoteChange}
                          endAdornment={
                            <IconButton
                              type="submit"
                              onClick={(e) => AddNote(e, patientId)}
                            >
                              <AddBoxIcon style={{ color: "#4cd3c5" }} />
                            </IconButton>
                          }
                        />
                      </FormControl>
                      {notesData.length !== 0 && (
                        <div
                          id="list"
                          style={{
                            height: "200px",
                          }}
                        >
                          <ScrollableFeed>
                            {notesData.map((noteitems) => {
                              return (
                                <ListItem className="listItems">
                                  <ListItemText className="listItems_data">
                                    {noteitems.note
                                      ? noteitems.note
                                      : noteitems.notes}
                                  </ListItemText>
                                </ListItem>
                              );
                            })}
                          </ScrollableFeed>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                      <span className="span_text">Status Update:</span>
                      <div
                        style={{
                          marginTop: "10px",
                          height: "200px",
                        }}
                      >
                        <ScrollableFeed>
                          {dataItems.audits.map((auditItem) => {
                            return (
                              <ListItem className="listItems">
                                <ListItemText className="listItems_data">
                                  {auditItem.adminName} updated{" "}
                                  {auditItem.status} at{" "}
                                  {moment(auditItem.date).format("MM-DD-yyyy")}
                                </ListItemText>
                              </ListItem>
                            );
                          })}
                        </ScrollableFeed>
                      </div>
                    </Grid>
                  </Grid>
                  {status == "Approved/VAMS Updated" ||
                    status == "Approved/VAMS Pending" || changeValue.includes("Approval Module(view)") ? (
                    <div></div>
                  ) : (
                    <Grid item xs={12} md={12} sm={12}>
                      <div className="d-flex my">
                        <div>
                          <button onClick={handleAddClick} className="srchbtn">
                            Add Dose
                          </button>
                        </div>
                        <div className="mx">
                          <button onClick={(e) => downloadPDF(e, patientId)} className="downloadBtn">
                            Download
                            <PictureAsPdfIcon style={{ paddingLeft: "3px" }} />
                          </button>
                        </div>
                        <div>
                          <button
                            className="srchbtn"
                            onClick={(e) => updateData(e, patientId)}
                          >
                            Save
                          </button>
                        </div>
                        <div className="mx">
                          <button
                            className="srchbtn"
                            style={{ width: "170px" }}
                            onClick={(e) => resendNotifiy(e, patientId)}
                          >
                            Resend notificaion
                          </button>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={Modalopen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );
}

export default Row;
