import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
    Grid,
    Typography,
    TextField,
    Paper,
    Divider,
    Box,
    InputLabel,
    FormControl,
    CircularProgress,
    Select,
    ListItem,
    ListItemText
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Switch from "react-switch";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ScrollableFeed from "react-scrollable-feed";
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import AddBoxIcon from '@mui/icons-material/AddBox';
import * as API from "../../Service/API/index.js";

const Form = () => {

    let history = useHistory();

    //======States Declare======//
    const [DOB, setDOB] = useState(null);
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("GB");
    const [state, setState] = useState("BVI+British Virgin Islands");
    const [county, setcounty] = useState("");
    const [EfirstName, setEfirstName] = useState("");
    const [ElastName, setElastName] = useState("");
    const [Emergencynumber, setEmergencynumber] = useState("");
    const [EmergencydialCode, setEmergencydialCode] = useState("");
    const [newnumber, setNumber] = useState("");
    const [dialCode, setdialCode] = useState("");
    const [receiveSMS, setreceiveSMS] = React.useState(true);
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState("");
    const [identifierName, setidentifierName] = useState("");
    const [IdentifierCode, setIdentifierCode] = useState("");
    const [IdentifierType, setIdentifierType] = useState("");
    const [VaccineType, setVaccineType] = useState("COVID-19");
    const [countyList, setcountyList] = React.useState([]);
    const [stateList, setstateList] = React.useState([]);
    const [dummyStateList, setdummyStateList] = React.useState([]);
    const [dummyCountyList, setdummyCountyList] = React.useState([]);
    const [countryList, setCountryList] = React.useState([]);
    const [vaccineNames, setvaccineNames] = React.useState([]);
    const [raceValues, setraceValues] = React.useState([]);
    const [EthnicityList, setEthnicityList] = React.useState([]);
    const [IdentifierList, setIdentifierList] = React.useState(["Driver's License", "Gov't Issued Source", "National Health Insurance", "Passport", "Social Security Number"]);
    const [Stateloader, setStateloader] = React.useState(false);
    const [countyLoader, setcountyLoader] = React.useState(false);
    const [countyLoaderIndex, setcountyLoaderIndex] = React.useState("");
    const [stateLoaderIndex, setstateLoaderIndex] = React.useState("");
    const [loader, setloader] = React.useState(false);
    const [details, setDetails] = useState({
        firstName: "",
        lastName: "",
        addressOne: "",
        addressTwo: "",
        city: "",
        Birthplace: "",
        race: "",
        gender: "",
        ethnicity: "",
        ResidencyStatus: ""
    });
    let countyListvalues = [["Anegada", { id: 149107, name: 'Anegada' }],
    ["Jost Van Dyke", { id: 149109, name: 'Jost Van Dyke' }],
    ["Tortola Central", { id: 149104, name: 'Tortola Central' }],
    ["Tortola East", { id: 149105, name: 'Tortola East' }],
    ["Tortola West", { id: 149106, name: 'Tortola West' }],
    ["Virgin Gorda", { id: 149108, name: 'Virgin Gorda' }]]
    const [doseList, setdoseList] = React.useState([
        {
            vaccineName: "",
            vaccineDate: null,
            lotNumber: "",
            doseCounty: "",
            doseCity: "",
            country: "GB",
            state: "BVI,British Virgin Islands",
            labId: 32,
            location: "Other",
            otherLabName: "",
            stateList: [],
            countyList: countyListvalues,
            vaccinationCategory: "",
            injectionSite: "",
            doseMeasurement: "",
            allergic: ""
        },
    ]);

    //======Error State Declare======//
    const [Error, setError] = useState("");
    const [ErrorField, setErrorField] = useState("");

    //====Scrolling Ref====// 
    const firstBlockRef = useRef(null);
    // const secondBlockRef = useRef(null);
    // const thirdBlockRef = useRef(null);
    // const fourthBlockRef = useRef(null);
    // const fifthBlockRef = useRef(null);
    // const sixthBlockRef = useRef(null);

    const GetVaccinationNames = () => {
        API.GetVaccination_API((res) => {
            setvaccineNames(res.info);
        });
    };

    const GetDropdownDetails = () => {
        API.GET_TEST_FORM_DROPDOWN_URL((res) => {
            setraceValues(res.info.races);
            setEthnicityList(res.info.ethinicitys);
        });
    };

    //======Get CountryList======//
    const GetCountryList = () => {
        API.GET_BVI_COUNTRYLIST((res) => {
            setCountryList(res.info);
        });
    };

    //======Get Statelist======//
    const GetStateList = () => {
        let data = {
            countryId: 232,
        };
        API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
                let allEntries = Object.entries(res.info.states);
                let sortedEntries = allEntries.sort((a, b) =>
                    a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
                );
                setdummyStateList(sortedEntries);
                setstateList(sortedEntries);
                setdoseList(
                    [
                        {
                            vaccineName: "",
                            vaccineDate: null,
                            lotNumber: "",
                            doseCounty: "",
                            doseCity: "",
                            country: "GB",
                            state: "BVI,British Virgin Islands",
                            labId: 32,
                            location: "Other",
                            otherLabName: "",
                            stateList: sortedEntries,
                            countyList: countyListvalues,
                            vaccinationCategory: "",
                            injectionSite: "",
                            doseMeasurement: "",
                            allergic: ""

                        },
                    ]
                )
            }
        });
    }

    //======Get County List======//
    const GetCountyList = () => {
        let data = {
            stateId: 4967,
        };
        API.GET_BVI_CITYLIST(data, (res) => {
            let allEntries = Object.entries(res.info.cities);
            let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            setdummyCountyList(sortedEntries);
            setcountyList(sortedEntries);
        });
    };

    useEffect(() => {
        if (localStorage.getItem("accessToken")) {
            GetVaccinationNames();
            GetCountryList();
            GetStateList();
            GetDropdownDetails();
            GetCountyList();
        } else {
            history.push("/");
        }
    }, []);

    //======OnChange Functionalities======//
    const detailsonChange = (e) => {
        let value = e.target.value.replaceAll('"', "'");
        setDetails({ ...details, [e.target.name]: value.replaceAll("''", "'") });
    };

    const searchDobHandleChange = (date) => {
        setDOB(date);
    };

    const mobileNumberChange = (number, e) => {
        setdialCode(e.dialCode);
        setNumber(number);
    };

    const EmergencyFirstNameOnChange = (event) => {
        let value = event.target.value.replaceAll('"', "'");
        setEfirstName(value.replaceAll("''", "'"));
    };

    const EmergencyLastNameOnChange = (event) => {
        let value = event.target.value.replaceAll('"', "'");
        setElastName(value.replaceAll("''", "'"));
    };

    const EmergencyNumberChange = (number, e) => {
        setEmergencynumber(number);
        setEmergencydialCode(e.dialCode);
    };
    const [passwordShown, setPasswordShown] = useState(false);

    const IdentifierListOnChange = (e) => {
        setIdentifierType("");
        setidentifierName(e.target.value);
    }

    const IdentifierOnChange = (e) => {
        setIdentifierCode(e.target.value);
    }

    const handleTogglePassword = () => setPasswordShown(passwordShown => !passwordShown);
    const countryOnchange = (e) => {
        setCountry(e.target.value);
        countryList.map((item) => {
            if (item.countryCode == e.target.value) {
                let data = {
                    countryId: item.id,
                };
                API.GET_BVI_STATELIST(data, (res) => {
                    if (res.statusCode === 200) {
                        setState("");
                        let allEntries = Object.entries(res.info.states);
                        let sortedEntries = allEntries.sort((a, b) =>
                            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
                        );
                        setstateList(sortedEntries);
                    }
                });
            }
        });
    }

    const stateOnchange = (e) => {
        setState(e.target.value);
        let check1 = e.target.value.split("+");
        setcounty("");
        setDetails({ ...details, ["city"]: "" });
        stateList.map((item) => {
            if (item[1].stateCode == check1[0]) {
                let data = {
                    stateId: item[1].id,
                };
                API.GET_BVI_CITYLIST(data, (res) => {
                    let allEntries = Object.entries(res.info.cities);
                    let sortedEntries = allEntries.sort((a, b) =>
                        a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
                    );
                    setcountyList(sortedEntries);
                });
            }
        });
    }

    const CountyOnchange = (e) => {
        setcounty(e.target.value);
    }

    const doseOnchange = (e, index, field) => {
        if (field === "vaccineDate") {
            const list = [...doseList];
            list[index]["vaccineDate"] = e === null ? null : moment(e);
            setdoseList(list);
        } else if (e.target.value && field === "vaccineName") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setdoseList(list);
        } else if (field === "lotNumber") {
            const name = e.target.name;
            const value = e.target.value.replaceAll('"', "'");
            const list = [...doseList];
            list[index][name] = value.replaceAll("''", "'");
            setdoseList(list);
        } else if (field === "country") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setdoseList(list);
            setStateloader(true);
            countryList.map((item) => {
                if (item.countryCode == value) {
                    let data = {
                        countryId: item.id,
                    };
                    API.GET_BVI_STATELIST(data, (res) => {
                        if (res.statusCode === 200) {
                            const list = [...doseList];
                            list[index]["state"] = "";
                            let allEntries = Object.entries(res.info.states);
                            let sortedEntries = allEntries.sort((a, b) =>
                                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
                            );
                            list[index]["stateList"] = sortedEntries;
                            setStateloader(false);
                        }
                    });
                }
            });
            setdoseList(list);
        } else if (field === "state") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setdoseList(list);
            setcountyLoader(true);
            let check1 = e.target.value.split(",");
            list[index]["stateList"].map((item) => {
                if (item[1].stateCode == check1[0]) {
                    let data = {
                        stateId: item[1].id,
                    };

                    API.GET_BVI_CITYLIST(data, (res) => {
                        if (res.statusCode === 200) {
                            list[index]["doseCounty"] = "";
                            list[index]["doseCity"] = "";
                            let allEntries = Object.entries(res.info.cities);
                            let sortedEntries = allEntries.sort((a, b) =>
                                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
                            );
                            list[index]["countyList"] = sortedEntries;
                            setcountyLoader(false);
                        }

                    });
                } 
            });
        } else if (field === "doseCounty") {
            const { name, value } = e.target;
            const list = [...doseList];
            list[index][name] = value;
            setdoseList(list);
        } else if (field === "doseCity") {
            const name = e.target.name;
            const value = e.target.value.replaceAll('"', "'");
            const list = [...doseList];
            list[index][name] = value.replaceAll("''", "'");
            setdoseList(list);
        } else if (field === "otherLabName") {
            const name = e.target.name;
            const value = e.target.value.replaceAll('"', "'");
            const list = [...doseList];
            list[index][name] = value.replaceAll("''", "'");
            setdoseList(list);
        } else if (field === "injectionSite" || field === "doseMeasurement" || field === "allergic") {
            const name = e.target.name;
            const value = e.target.value.replaceAll('"', "'");
            const list = [...doseList];
            list[index][name] = value.replaceAll("''", "'");
            setdoseList(list);
        }
    }

    //======Add Dose Function======//

    const addDose = () => {
        let arr = doseList.map((item) => {
            return {
                vaccinationType: item.vaccineName,
                doseDate: moment(item.vaccineDate).format("yyyy-MM-DD"),
                doseLot: item.lotNumber.trim(),
                doseCountry: item.country,
                doseState: item.state,
                doseCounty: item.doseCounty,
                doseCity: item.doseCity,
                countyList: item.countyList,
                doseLabId: item.labId,
                doseLocation: item.location,
                doseOtherLabName: item.location === "Other" ? item.otherLabName.trim() : "",
            };
        });

        let filter_data = arr.filter(function (x, i) {
            if (x.vaccinationType != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseDate != "Invalid date" || x.doseDate != null) {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseLot.trim() != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseCountry != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseState != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.countyList.length && x.doseCounty != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (!x.countyList.length && x.doseCity != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseLabId != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseLabId !== 32 && x.doseOtherLabName.trim() !== "") {
                setErrorField("");
                setError("");
                return true;
            }
        });

        let Checkfilter_data = arr.filter(function (x, i) {
            if (x.vaccinationType == "") {
                setErrorField("vaccineName");
                setError("Please select the vaccination type");
                return true;
            } else if (x.doseDate === "Invalid date" || x.doseDate === null) {
                setErrorField("vaccineDate");
                setError("Please enter the vaccination date");
                return true;
            } else if (x.doseLot.trim() == "") {
                setErrorField("lotNumber");
                setError("Please enter the LOT number");
                return true;
            } else if (x.doseCountry == "") {
                setErrorField("doseCountry");
                setError("Please select the country");
                return true;
            } else if (x.doseState == "") {
                setErrorField("doseState");
                setError("Please select the location");
                return true;
            } else if (x.countyList.length && x.doseCounty == "") {
                setErrorField("doseCounty");
                setError("Please select the county");
                return true;
            } else if (!x.countyList.length && x.doseCity == "") {
                setErrorField("doseCity");
                setError("Please enter the city");
                return true;
            } else if (x.doseLabId == "") {
                setErrorField("doseLabId");
                setError("Please select thelab");
                return true;
            } else if (x.location == "") {
                return true;
            } else if (x.doseLabId == 32 && x.doseOtherLabName.trim() == "") {
                setErrorField("otherLabName");
                setError("Please enter the site name");
                return true;
            }
        });

        if (Checkfilter_data.length === 0) {
            setdoseList([
                ...doseList,
                {
                    vaccineName: "",
                    vaccineDate: null,
                    lotNumber: "",
                    country: "GB",
                    state: "BVI,British Virgin Islands",
                    doseCounty: "",
                    doseCity: "",
                    labId: 32,
                    location: "Other",
                    otherLabName: "",
                    stateList: dummyStateList,
                    countyList: dummyCountyList,
                    vaccinationCategory: "",
                    injectionSite: "",
                    doseMeasurement: "",
                    allergic: ""
                },
            ]);
        }
    }

    const handleRemoveClick = (indexvalue) => {
        const list = [...doseList];
        list.splice(indexvalue, 1);
        setdoseList(list);
    }

    const addNote = (e) => {
        e.preventDefault();
        if (note.trim() != "") {
            const newData = [...notes, note];
            setNotes(newData);
            setNote("");
        }
        else {
            toast.error("Please enter the note");
        }
    }

    const RemoveNote = (indexVal, event) => {
        const noteList = [...notes];
        noteList.splice(indexVal, 1);
        setNotes(noteList);
    }

    //======Update Details Function======//
    const SaveData = (e) => {
        e.preventDefault();

        let arr = doseList.map((item) => {
            return {
                vaccinationType: item.vaccineName,
                doseDate: moment(item.vaccineDate).format("yyyy-MM-DD"),
                doseLot: item.lotNumber.trim(),
                doseCountry: item.country,
                doseCounty: item.doseCounty,
                doseCity: item.doseCity,
                doseState: item.state,
                doseLabId: item.labId,
                doseLocation: item.location,
                countyList: item.countyList,
                doseOtherLabName: item.location === "Other" ? item.otherLabName.trim() : "",
                vaccinationCategory: VaccineType,
                injectionSite: item.injectionSite,
                doseMeasurement: item.doseMeasurement,
                allergic: item.allergic,
            };
        });

        let filter_data = arr.filter(function (x, i) {
            if (x.vaccinationType != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseDate != "Invalid date" || x.doseDate != null) {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseLot.trim() != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseCountry != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseState != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.countyList.length && x.doseCounty != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (!x.countyList.length && x.doseCity != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseLabId != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseLabId !== 32 && x.doseOtherLabName.trim() !== "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.vaccinationCategory != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.injectionSite.trim() != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.doseMeasurement.trim() != "") {
                setErrorField("");
                setError("");
                return true;
            } else if (x.allergic.trim() != "") {
                setErrorField("");
                setError("");
                return true;
            }
        });

        const newFilterArr = filter_data.map(({ countyList, ...rest }) => {
            return rest;
        });

        let Checkfilter_data = arr.filter(function (x, i) {
            if (x.vaccinationType == "") {
                setErrorField("vaccineName");
                setError("Please select the vaccination type");
                return true;
            } else if (x.doseDate === "Invalid date" || x.doseDate === null) {
                setErrorField("vaccineDate");
                setError("Please enter the vaccination date");
                return true;
            } else if (x.doseLot.trim() == "") {
                setErrorField("lotNumber");
                setError("Please enter the LOT number");
                return true;
            } else if (x.doseCountry == "") {
                setErrorField("doseCountry");
                setError("Please select the country");
                return true;
            } else if (x.doseState == "") {
                setErrorField("doseState");
                setError("Please select the location");
                return true;
            } else if (x.countyList.length && x.doseCounty == "") {
                setErrorField("doseCounty");
                setError("Please select the county");
                return true;
            } else if (!x.countyList.length && x.doseCity == "") {
                setErrorField("doseCity");
                setError("Please enter the city");
                return true;
            } else if (x.doseLabId == "") {
                setErrorField("doseLabId");
                setError("Please select thelab");
                return true;
            } else if (x.location == "") {
                return true;
            } else if (x.doseLabId == 32 && x.doseOtherLabName.trim() == "") {
                setErrorField("otherLabName");
                setError("Please enter the site name");
                return true;
            }
        });

        if (details.firstName.trim() === "") {
            setErrorField("firstName");
            setError("Please enter the First name");
            firstBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (details.lastName.trim() === "") {
            setErrorField("lastName");
            setError("Please enter the Last name");
            firstBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (DOB === "Invalid date" || DOB === null) {
            setErrorField("DOBDate");
            setError("Please enter the Date of birth");
            firstBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (DOB == "Invalid Date" ||
            moment(DOB).format("yyyy") <= "1899" == true ||
            DOB > new Date() == true) {
            setErrorField("DOBDate");
            setError("Please enter the valid date of birth");
            firstBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (doseList.length) {
            for (let i = 0; i < doseList.length; i++) {
                if (doseList[i].vaccineName === "") {
                    setErrorField("vaccineName");
                    setError("Please select the vaccination type");
                } else if (
                    doseList[i].vaccineDate === "Invalid date" ||
                    doseList[i].vaccineDate === null) {
                    setErrorField("vaccineDate");
                    setError("Please enter the vaccination date");
                } else if (doseList[i].lotNumber.trim() === "") {
                    setErrorField("lotNumber");
                    setError("Please enter the LOT number");
                } else if (doseList[i].country === "") {
                    setErrorField("doseCountry");
                    setError("Please select the country");
                } else if (doseList[i].state === "") {
                    setErrorField("doseState");
                    setError("Please select the location");
                } else if (doseList[i].countyList.length && doseList[i].doseCounty === "") {
                    setErrorField("doseCounty");
                    setError("Please select the county");
                } else if (!doseList[i].countyList.length && doseList[i].doseCity === "") {
                    setErrorField("doseCity");
                    setError("Please enter the city");
                } else if (doseList[i].labId === "") {
                    setErrorField("doseLabId");
                    setError("Please select thelab");
                } else if (
                    doseList[i].labId === 32 &&
                    doseList[i].otherLabName.trim() === ""
                ) {
                    setErrorField("otherLabName");
                    setError("Please enter the site name");
                }

            }
            if (Checkfilter_data.length === 0) {
                console.log(">>>>>>> API call >>>>>>>>>>");
                setloader(true);
                let splitstate = state.split("+");
                let data = {
                    firstName: details.firstName.trim(),
                    lastName: details.lastName.trim(),
                    email: email.trim(),
                    dob: moment(DOB).format("yyyy-MM-DD"),
                    mobileNumber: newnumber == "" ? "" : newnumber.includes("+") ? newnumber : "+" + newnumber,
                    phoneCode: dialCode,
                    addressOne: details.addressOne.trim(),
                    addressTwo: details.addressTwo.trim(),
                    state: splitstate[0],
                    country: country,
                    county: countyList.length && county !== "" ? county : "",
                    city: countyList.length == 0 ? details.city : "",
                    gender: details.gender,
                    race: details.race,
                    ethinicity: details.ethnicity,
                    identifier: identifierName,
                    identifierCode: IdentifierCode.trim(),
                    identifierOtherValidSource: IdentifierType.trim(),
                    placeOfBirth: details.Birthplace.trim(),
                    residency: details.ResidencyStatus,
                    emergencyContactFirstName: EfirstName.trim(),
                    emergencyContactLastName: ElastName.trim(),
                    emergencyContactPhoneNumber: Emergencynumber == "" ? "" : Emergencynumber.includes("+") ? Emergencynumber : "+" + Emergencynumber,
                    sendNotificationToMobile: receiveSMS,
                    submittedFrom: "HSA",
                    notes: notes,
                    receivedBothDoses: newFilterArr.length > 1 ? "Yes" : "No",
                    doses: newFilterArr,

                };
                console.log("Data", data)
                //ADD_VAX_PATIENT

                API.ADD_VAX_PATIENT(data, (res) => {
                    if (res.statusCode === 200) {
                        toast.success("Patient details added successfully");
                        window.scrollTo(0, 0);
                        setloader(false);
                        setDetails({
                            firstName: "",
                            lastName: "",
                            addressOne: "",
                            addressTwo: "",
                            city: "",
                            Birthplace: "",
                            race: "",
                            gender: "",
                            ethnicity: "",
                            ResidencyStatus: ""
                        });
                        setIdentifierCode("");
                        setdoseList([
                            {
                                vaccineName: "",
                                vaccineDate: null,
                                lotNumber: "",
                                doseCounty: "",
                                doseCity: "",
                                country: "GB",
                                state: "BVI,British Virgin Islands",
                                labId: 32,
                                location: "Other",
                                otherLabName: "",
                                stateList: dummyStateList,
                                countyList: dummyCountyList,
                                vaccinationCategory: "",
                                injectionSite: "",
                                doseMeasurement: "",
                                allergic: ""
                            },
                        ]);

                        setErrorField("");
                        setError("");
                        setNotes([]);
                        setNote("");
                        setNumber("");
                        setdialCode("");
                        setEmail("");
                        setDOB(null);
                        setCountry("GB");
                        setIdentifierType("");
                        setState("BVI+British Virgin Islands");
                        setcounty("");
                        setVaccineType("COVID-19")
                        setEfirstName("");
                        setElastName("");
                        setEmergencynumber("");
                        setEmergencydialCode("");
                        setidentifierName("");
                        setPasswordShown(false);
                        GetCountyList();
                    } else {
                        setloader(false);
                        toast.error(res.info);
                    }
                });
            }
        }
        else {
            console.log("Final")
            setErrorField("");
            setError("");
        }

    }

    return (
        <React.Fragment>
            <ToastContainer autoClose={2000} />
            <div className="header_title">BVI PolarisKey</div>
            <Divider />
            <div className="module_title my">Patient Quick Approval - All patients are approved with the status of <span style={{ fontWeight: "bold" }}>Registry Approval - L2</span></div>
            {loader == true ? (
                <div className="loaderAdj">
                    <CircularProgress />
                </div>
            ) : (<Paper fullWidth elevation={3} style={{ width: "97%", padding: "20px", margin: "5px 0px" }}>
                <div style={{ fontWeight: "400" }}>General Information</div>
                <Grid style={{ marginTop: "5px" }} container spacing={2} ref={firstBlockRef}>
                    <Grid item xs={12} md={3} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth
                            id="outlined-search"
                            label="First name *"
                            type="text"
                            name="firstName"
                            inputProps={{ maxLength: 30 }}
                            value={details.firstName}
                            autoComplete="off"
                            onChange={detailsonChange}
                            variant="outlined" />
                        {ErrorField === "firstName" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth
                            id="outlined-search"
                            label="Last name *"
                            type="text"
                            name="lastName"
                            inputProps={{ maxLength: 30 }}
                            value={details.lastName}
                            autoComplete="off"
                            onChange={detailsonChange}
                            variant="outlined" />
                        {ErrorField === "lastName" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                inputVariant="outlined"
                                className="Addform_DatePicker"
                                autoComplete="off"
                                clearable
                                name="DOBDate"
                                format="MM/dd/yyyy"
                                maxDate={new Date()}
                                value={DOB}
                                onChange={(date) => searchDobHandleChange(date)}
                                autoOk
                                required
                                label={
                                    <Typography variant="inherit" component="span">
                                        DOB <span className="adjs">(MM/DD/YYYY)</span>
                                    </Typography>
                                }
                            />
                        </MuiPickersUtilsProvider>
                        {ErrorField === "DOBDate" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12} className="gridHide">
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>
                                Identifier
                            </InputLabel>
                            <Select
                                label="Identifier"
                                name="IdentifierName"
                                value={identifierName}
                                onChange={IdentifierListOnChange}
                            >
                                {IdentifierList.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            {ErrorField === "IdentifierName" && Error !== "" ? (
                                <div className="errorText">
                                    {Error}
                                </div>
                            ) : null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth id="outlined-search"
                            label={
                                <Typography variant="inherit" component="span">
                                    <span style={{ marginRight: "5px" }}>Identifier code</span>
                                </Typography>
                            }
                            type={passwordShown ? "text" : "password"}
                            name="IdentifierCode"
                            inputProps={{ maxLength: 30 }}
                            value={IdentifierCode}
                            autoComplete="off"
                            onChange={event => IdentifierOnChange(event)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        disableRipple
                                        edge="end" onClick={handleTogglePassword}>
                                        {passwordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            variant="outlined"
                        />
                        {ErrorField === "IdentifierCode" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    {identifierName == "Gov't Issued Source" ? (<>
                        <Grid item xs={12} md={3} sm={12}>
                            <TextField
                                className="Addform_textfield"
                                fullWidth
                                id="outlined-search"
                                label="Identifier Type"
                                type="text"
                                name="IdentiferType"
                                inputProps={{ maxLength: 30 }}
                                value={IdentifierType}
                                autoComplete="off"
                                onChange={(e) => setIdentifierType(e.target.value)}
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={12} md={3} sm={12} className="gridHide"></Grid></>) : <Grid item xs={12} md={6} sm={12} className="gridHide"></Grid>}
                    <Grid item xs={12} md={3} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth id="outlined-search"
                            label="Address 1"
                            type="text"
                            name="addressOne"
                            inputProps={{ maxLength: 30 }}
                            value={details.addressOne}
                            autoComplete="off"
                            onChange={detailsonChange}
                            variant="outlined" />
                        {ErrorField === "addressOne" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth id="outlined-search"
                            label="Address 2"
                            type="text"
                            name="addressTwo"
                            inputProps={{ maxLength: 30 }}
                            value={details.addressTwo}
                            autoComplete="off"
                            onChange={detailsonChange}
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} className="gridHide"></Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>Country</InputLabel>
                            <Select
                                label="Country"
                                className="Addform_menuitems"
                                name="country"
                                value={country}
                                onChange={countryOnchange}
                            >
                                {countryList.map((item) => (
                                    <MenuItem key={item.id} value={item.countryCode}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {ErrorField === "country" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>Location</InputLabel>
                            <Select
                                label="Location"
                                className="Addform_menuitems"
                                name="Location"
                                value={state}
                                onChange={stateOnchange}
                            >
                                {stateList.map((item) => (
                                    <MenuItem key={item[1].stateCode} value={item[1].stateCode + "+" + item[0]}>
                                        {item[0]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {ErrorField === "Location" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    {countyList.length ? (<Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>County/district</InputLabel>
                            <Select
                                label={<span style={{ marginRight: "8px" }}>County/district</span>}
                                className="Addform_menuitems"
                                name="County"
                                value={county}
                                onChange={CountyOnchange}
                            >
                                {countyList.map((item) => (
                                    <MenuItem key={item[1].id} value={item[0]}>{item[0]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {ErrorField === "County" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>) : (<Grid item xs={12} md={3} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth
                            id="outlined-search"
                            label="City"
                            inputProps={{ maxLength: 30 }}
                            autoComplete="off"
                            type="text"
                            name="city"
                            value={details.city}
                            onChange={detailsonChange}
                            variant="outlined" />
                        {ErrorField === "city" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>)}
                    <Grid item xs={12} md={3} sm={12} className="gridHide"></Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>
                                Residency status
                            </InputLabel>
                            <Select
                                label={<span style={{ marginRight: "8px" }}>Residency status</span>}
                                name="ResidencyStatus"
                                value={details.ResidencyStatus}
                                onChange={detailsonChange}
                            >
                                <MenuItem value={"BVIslander/Belonger"}>
                                    BVIslander/Belonger
                                </MenuItem>
                                <MenuItem value={"BVI Resident"}>BVI Resident</MenuItem>
                                <MenuItem value={"Tourist"}>Tourist</MenuItem>
                                <MenuItem value={"Work Permit Holder/Exempt"}>Work Permit Holder/Exempt</MenuItem>
                            </Select>
                            {ErrorField === "ResidencyStatus" && Error !== "" ? (
                                <div className="errorText">
                                    {Error}
                                </div>
                            ) : null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth id="outlined-search"
                            label="Email"
                            inputProps={{ maxLength: 50, autoComplete: "none" }}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined" />
                        {ErrorField === "Email" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid className="Addform_phonenumber" item xs={12} md={3} sm={12}>
                        <PhoneInput
                            country={"us"}
                            required
                            name="mobileNumber"
                            onChange={mobileNumberChange}
                            value={newnumber}
                        />
                        {ErrorField === "phoneNumber" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid className="d-flex" style={{ justifyContent: "flex-start" }} item xs={12} md={3} sm={12}>
                        <Switch
                            checked={receiveSMS}
                            onChange={() => setreceiveSMS(!receiveSMS)}
                            name="sendTextToMobile"
                        />
                        <span
                            style={{
                                color: "#808080",
                                marginLeft: "10px",
                                fontSize: "15px",
                            }}
                        >
                            Can this number receive text messages?
                        </span>
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: "24px" }} />
                <div className="mt" style={{ fontWeight: "400", marginBottom: "10px" }}>Emergency Contact</div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3} sm={12} >
                        <TextField
                            className="Addform_textfield"
                            fullWidth id="outlined-search"
                            label="First name"
                            type="text"
                            name="EfirstName"
                            inputProps={{ maxLength: 30 }}
                            value={EfirstName}
                            autoComplete="off"
                            onChange={EmergencyFirstNameOnChange}
                            variant="outlined" />
                        {ErrorField === "EfirstName" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12} >
                        <TextField
                            className="Addform_textfield"
                            fullWidth id="outlined-search"
                            label="Last name"
                            type="text"
                            name="ElastName"
                            inputProps={{ maxLength: 30 }}
                            value={ElastName}
                            autoComplete="off"
                            onChange={EmergencyLastNameOnChange}
                            variant="outlined" />
                        {ErrorField === "ElastName" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid className="Addform_phonenumber" item xs={12} md={3} sm={12}>
                        <PhoneInput
                            country={"us"}
                            required
                            name="Emergencynumber"
                            onChange={EmergencyNumberChange}
                            value={Emergencynumber}
                        />
                        {ErrorField === "Emergencynumber" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: "24px" }} />
                <div className="mt" style={{ fontWeight: "400" }}>Additional Demographics</div>
                <Grid style={{ marginTop: "1px" }} container spacing={2}>
                    <Grid item xs={12} md={6} sm={12}>
                        <TextField
                            className="Addform_textfield"
                            fullWidth id="outlined-search"
                            label={<span style={{ marginRight: "6px" }}>Place of birth</span>}
                            type="text"
                            name="Birthplace"
                            inputProps={{ maxLength: 30 }}
                            value={details.Birthplace}
                            autoComplete="off"
                            onChange={detailsonChange}
                            variant="outlined" />
                        {ErrorField === "Birthplace" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} className="gridHide"></Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            className="Addform_dropdown"
                            fullWidth
                        >
                            <InputLabel>Gender</InputLabel>
                            <Select
                                label="Gender"
                                name="gender"
                                value={details.gender}
                                onChange={detailsonChange}
                            >
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value="Ambiguous">Non-binary</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                        </FormControl>
                        {ErrorField === "gender" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>Race</InputLabel>
                            <Select
                                label="Race"
                                name="race"
                                className="Addform_menuitems"
                                value={details.race}
                                onChange={detailsonChange}
                            >
                                {raceValues.map((item) => (
                                    <MenuItem key={item.raceName} value={item.raceName}>
                                        {item.raceName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {ErrorField === "race" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>Ethnicity</InputLabel>
                            <Select
                                aria-label="ethnicity"
                                label="Ethnicity"
                                name="ethnicity"
                                value={details.ethnicity}
                                onChange={detailsonChange}
                                required
                            >
                                {EthnicityList.map((item) => (
                                    <MenuItem key={item.ethinicityName} value={item.ethinicityName}>
                                        {item.ethinicityName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {ErrorField === "ethnicity" && Error !== "" ? (
                            <div className="errorText">
                                {Error}
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} md={3} sm={12} className="gridHide"></Grid>
                    {/* <Grid item xs={12} md={3} sm={12}>
                        <FormControl
                            fullWidth
                            className="Addform_dropdown">
                            <InputLabel>
                                Vaccine type
                            </InputLabel>
                            <Select
                                label="Vaccine type"
                                name="VaccineType"
                                value={VaccineType}
                                onChange={(e) => setVaccineType(e.target.value)}
                            >
                                <MenuItem key={"COVID-19"} value={"COVID-19"}>
                                    COVID-19
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                </Grid>
                {doseList.map((dose, i) => {
                    return (
                        <React.Fragment key={i}>
                            <Divider style={{ marginTop: "24px" }} />
                            {i == 0 ? (<Grid className="mt" container spacing={3}>
                                <Grid item xs={12} md={3} sm={12}>
                                    <FormControl
                                        fullWidth
                                        className="Addform_dropdown">
                                        <InputLabel>
                                            Vaccine type
                                        </InputLabel>
                                        <Select
                                            label="Vaccine type"
                                            name="VaccineType"
                                            value={VaccineType}
                                            onChange={(e) => setVaccineType(e.target.value)}
                                        >
                                            <MenuItem key={"COVID-19"} value={"COVID-19"}>
                                                COVID-19
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>) : null}

                            <Box style={{ margin: "16px 0px" }}>
                                <div className="headingLine d-flex" style={{ justifyContent: "space-between" }}>
                                    <div style={{ fontWeight: "500" }}>
                                        Dose
                                        <span style={{ marginLeft: "3px" }}>
                                            {i + 1}
                                        </span></div>
                                    {doseList.length > 1 ? <button style={{ marginLeft: "10px", }}
                                        className="btn_outlined deleteIconBox"
                                        onClick={() => handleRemoveClick(i)}>
                                        Remove
                                        < DeleteOutlineIcon
                                            style={{
                                                width: "18px",
                                                height: "18px",
                                                paddingLeft: "4px"
                                            }}
                                        />
                                    </button> : null}
                                </div>
                                <Grid style={{ marginTop: "1px" }} container spacing={2}>
                                    <Grid item xs={12} md={3} sm={12} >
                                        <FormControl
                                            fullWidth
                                            className="Addform_dropdown">
                                            <InputLabel>Vaccination Name * </InputLabel>
                                            <Select
                                                label={<span style={{ marginRight: "8px" }}>Vaccination Name *</span>}
                                                className="Addform_menuitems"
                                                name="vaccineName"
                                                value={dose.vaccineName}
                                                onChange={(e) => doseOnchange(e, i, "vaccineName")}
                                            >
                                                {vaccineNames.map((item) => (
                                                    <MenuItem key={item.vaccineName} value={item.vaccineName}>
                                                        {item.vaccineName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {ErrorField === "vaccineName" && Error !== "" && dose.vaccineName === "" ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                fullWidth
                                                inputVariant="outlined"
                                                className="Addform_DatePicker"
                                                clearable
                                                name="vaccineDate"
                                                format="MM/dd/yyyy"
                                                maxDate={new Date()}
                                                autoComplete="off"
                                                value={dose.vaccineDate}
                                                onChange={(e) => doseOnchange(e, i, "vaccineDate")}
                                                autoOk
                                                label={
                                                    <Typography variant="inherit" component="span">
                                                        Date <span className="adjs">(MM/DD/YYYY) </span>
                                                    </Typography>
                                                }
                                                required
                                            />
                                        </MuiPickersUtilsProvider>
                                        {ErrorField === "vaccineDate" && Error !== "" && dose.vaccineDate === null ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} >
                                        <TextField
                                            className="Addform_textfield"
                                            fullWidth id="outlined-search"
                                            label="Lot # *"
                                            type="text"
                                            inputProps={{ maxLength: 30 }}
                                            autoComplete="off"
                                            value={dose.lotNumber}
                                            name="lotNumber"
                                            onChange={(e) => doseOnchange(e, i, "lotNumber")}
                                            variant="outlined" />
                                        {ErrorField === "lotNumber" && Error !== "" && dose.lotNumber === "" ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} className="gridHide" ></Grid>
                                    <Grid item xs={12} md={3} sm={12} >
                                        <FormControl
                                            fullWidth
                                            className="Addform_dropdown">
                                            <InputLabel>Country *</InputLabel>
                                            <Select
                                                label="Country *"
                                                className="Addform_menuitems"
                                                name="country"
                                                value={dose.country}
                                                onChange={(e) => doseOnchange(e, i, "country")}
                                            >
                                                {countryList.map((item) => (
                                                    <MenuItem key={item.id} value={item.countryCode}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {ErrorField === "doseCountry" && Error !== "" && dose.country === "" ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} >
                                        <FormControl
                                            fullWidth
                                            className="Addform_dropdown">
                                            <InputLabel>Location *</InputLabel>
                                            <Select
                                                label="Location *"
                                                className="Addform_menuitems"
                                                name="state"
                                                value={dose.state}
                                                onChange={(e) => doseOnchange(e, i, "state")}
                                            >
                                                {dose.stateList &&
                                                    dose.stateList.map((item, stateIndex) => (
                                                        <MenuItem key={stateIndex} value={item[1].stateCode + "," + item[0]}>
                                                            {item[0]}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        {ErrorField === "doseState" && Error !== "" && dose.state === "" ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid>

                                    {dose.countyList && dose.countyList.length ? <><Grid item xs={12} md={3} sm={12}>
                                        <FormControl
                                            fullWidth
                                            className="Addform_dropdown"
                                        >
                                            <InputLabel>County/District *</InputLabel>
                                            <Select
                                                label={<span style={{ marginRight: "8px" }}>County/District *</span>}
                                                className="Addform_menuitems"
                                                name="doseCounty"
                                                value={dose.doseCounty}
                                                onChange={(e) => doseOnchange(e, i, "doseCounty")}
                                            >
                                                {dose.countyList && dose.countyList.map((item) => (
                                                    <MenuItem value={item[0]}>{item[0]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {ErrorField === "doseCounty" && Error !== "" && dose.doseCounty === "" ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid></> : <Grid item xs={12} md={3} sm={12}>
                                        <TextField
                                            className="Addform_textfield"
                                            fullWidth id="outlined-search"
                                            label="City/Estate*"
                                            variant="outlined"
                                            type="text"
                                            name="doseCity"
                                            inputProps={{ maxLength: 40, autoComplete: "none" }}
                                            value={dose.doseCity}
                                            onChange={(e) => doseOnchange(e, i, "doseCity")}
                                        />
                                        {ErrorField === "doseCity" && Error !== "" && dose.doseCity === "" ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid>}

                                    <Grid item xs={12} md={3} sm={12} >
                                        <TextField
                                            className="Addform_textfield"
                                            fullWidth id="outlined-search"
                                            label={<span style={{ marginRight: "12px" }}>Please specify site location *</span>}
                                            type="text"
                                            name="otherLabName"
                                            value={dose.otherLabName}
                                            onChange={(e) => doseOnchange(e, i, "otherLabName")}
                                            inputProps={{ maxLength: 30, autoComplete: "off", }}
                                            autoComplete="off"
                                            variant="outlined" />
                                        {ErrorField === "otherLabName" && Error !== "" && dose.otherLabName === "" ? (
                                            <div className="errorText">
                                                {Error}
                                            </div>
                                        ) : null}
                                    </Grid>
                                    {/* <Grid item xs={12} md={3} sm={12} className="gridHide" >
                                    </Grid> */}
                                    <Grid item xs={12} md={6} sm={12}>
                                        <TextField
                                            className="Addform_textfield"
                                            fullWidth id="outlined-search"
                                            label={<span style={{ marginRight: "8px" }}>Injection site</span>}
                                            type="text"
                                            name="injectionSite"
                                            inputProps={{ maxLength: 30 }}
                                            value={dose.injectionSite}
                                            onChange={(e) => doseOnchange(e, i, "injectionSite")}
                                            autoComplete="off"
                                            variant="outlined" />

                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12}>
                                        <TextField
                                            className="Addform_textfield"
                                            fullWidth id="outlined-search"
                                            label={<span style={{ marginRight: "8px" }}>Dose measurement</span>}
                                            type="text"
                                            name="doseMeasurement"
                                            inputProps={{ maxLength: 30 }}
                                            value={dose.doseMeasurement}
                                            autoComplete="off"
                                            onChange={(e) => doseOnchange(e, i, "doseMeasurement")}
                                            variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} md={12} sm={12}>
                                        <TextField
                                            className="Addform_textfield allergice_textfield"
                                            fullWidth id="outlined-search"
                                            label={<span style={{ marginRight: "35px" }}>Severe allergic reaction to a vaccination or injection?</span>}
                                            type="text"
                                            name="allergic"
                                            inputProps={{ maxLength: 150 }}
                                            value={dose.allergic}
                                            autoComplete="off"
                                            onChange={(e) => doseOnchange(e, i, "allergic")}
                                            variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Box>
                        </React.Fragment>)
                })
                }
                <div className="btnDiv">
                    <button className="btn_outlined" onClick={addDose}>
                        < AddCircleOutlineIcon
                            style={{
                                width: "24px",
                                height: "24px",
                                paddingRight: "5px"
                            }}
                        />Add Dose
                    </button>
                </div>
                <TextField
                    style={{ marginTop: "20px" }}
                    className="Addform_textfield Addform_notes"
                    fullWidth id="outlined-search"
                    label="Notes"
                    name="notes"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    type="text"
                    autoComplete="off"
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="start" onClick={addNote}> < AddBoxIcon
                            className="IconColor"
                        /></InputAdornment>,
                    }}
                /> {notes.length !== 0 && (
                    <div
                        id="list"
                        className="listBox"
                    >
                        <ScrollableFeed>
                            {notes.map((noteitems, i) => {
                                return (
                                    <ListItem className="listItems addPatientListItems" key={i}>
                                        <ListItemText className="listItems_data">
                                            <div className="listItems_data_withIcon">
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "10px", marginRight: "8px" }}>{i + 1}.</div>{noteitems}</div>
                                                <div onClick={(e) => RemoveNote(i, e)}>
                                                    <CloseIcon style={{
                                                        color: "#f07167",
                                                        cursor: "pointer",
                                                        paddingRight: "17px"
                                                    }} /></div>
                                            </div>
                                        </ListItemText>
                                    </ListItem>
                                );
                            })}
                        </ScrollableFeed>
                    </div>
                )}
                <div className="d-flex my">
                    <div className="mx"></div>
                    <div><button className="srchbtn" onClick={SaveData}>Save</button></div>
                </div>
            </Paper>)}
        </React.Fragment>
    )
}

export default Form