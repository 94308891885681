import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import * as API from "../../Service/API/index.js";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grid,
  InputLabel,
  Input,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  FormControl,
  Box,
  Collapse,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  Select,
} from "@material-ui/core";
import Paper from '@mui/material/Paper';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Switch from "react-switch";
import Closeicon from "../../Assets/close.png";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@material-ui/core/Modal";
import ScrollableFeed from "react-scrollable-feed";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import ReactPanZoom from 'react-image-pan-zoom-rotate'

function Row({
  dataItems,
  stateSelect,
  countryList,
  labName,
  getlist,
  vaccineNames,
  setloader,
  keyvalue,
  expandedId,
  setExpandedId,
  handleExpandClick,
}) {
  const {
    patientId,
    firstName,
    lastName,
    mobileNumber,
    dob,
    status,
    middleName,
    sendTextToMobile,
    notes,
    formSubmittedOn,
    showRemoveButton
  } = dataItems;

  //Row data States
  const [StatusValue, SetstatusValue] = React.useState("");
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cardUrl: "",
    licenceUrl: "",
  });

  const [sendTextObj, setsendTextObj] = useState({
    sendTextToMobile: sendTextToMobile ? true : false,
  });

  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");
  const [dobDate, setdobDate] = React.useState(null);
  const [note, setNote] = React.useState("");
  const [notesData, setNotesData] = React.useState(notes);
  const [Modalopen, modalsetOpen] = React.useState(false);
  const [Stateloader, setStateloader] = React.useState(false);
  const [countyLoader, setcountyLoader] = React.useState(false);
  const [countyLoaderIndex, setcountyLoaderIndex] = React.useState("");
  const [stateLoaderIndex, setstateLoaderIndex] = React.useState("");
  const [removeDoseIds, setremoveDoseIds] = React.useState([]);
  const vaccinearray = dataItems.vaccinationDoseList.map(
    ({ doseDate, stateList, city, county, cityList, ...rest }) => ({
      doseDate: doseDate ? moment(doseDate) : null,
      stateList: Object.entries(stateList).sort((a, b) =>
        a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
      ),
      city: city ? city : "",
      county: county ? county : "",
      cityList: Object.entries(cityList).sort((a, b) =>
        a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
      ),
      ...rest,
    })
  );

  const [vaccineDoseList, setvaccineDoseList] = React.useState(vaccinearray);

  //====Scrolling Ref====//
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const dobRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);
  const vaccinenameRef = useRef(null);
  const vaccinedateRef = useRef(null);
  const lotRef = useRef(null);
  const countryRef = useRef(null);
  const stateRef = useRef(null);
  const countyRef = useRef(null);
  const cityRef = useRef(null);
  const otherLabRef = useRef(null);

  //Error states
  const [firstNameError, setfirstNameError] = React.useState(false);
  const [lastNameError, setlastNameError] = React.useState(false);
  const [dobError, setdobError] = React.useState(false);
  const [mobilenumberError, setmobilenumberError] = React.useState(false);
  const [mobilenumberLengthError, setmobilenumberLengthError] =
    React.useState(false);
  const [emailError, setemailError] = React.useState(false);
  const [emailFormetError, setemailFormetError] = React.useState(false);
  const [vaccinationError, setvaccinationError] = React.useState(false);
  const [vaccinationdateError, setvaccinationdateError] = React.useState(false);
  const [lotError, setlotError] = React.useState(false);
  const [stateError, setstateError] = React.useState(false);
  const [countyError, setcountyError] = React.useState(false);
  const [cityError, setcityError] = React.useState(false);
  const [locationError, setlocationError] = React.useState(false);
  const [OtherlocationError, setOtherlocationError] = React.useState(false);
  const [countryError, setcountryError] = React.useState(false);

  //========Onchange functionalities========//
  const onChange = (e) => {
    let value = e.target.value.replaceAll('"', "'");
    setDetails({ ...details, [e.target.name]: value.replaceAll("''", "'") });
  };

  const onChangeSendText = (e) => {
    setsendTextObj({ ...sendTextObj, "sendTextToMobile": e })
  }

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  const handleDobChange = (date) => {
    setdobDate(date);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleInputChange = (e, index, field) => {
    setfirstNameError(false);
    setlastNameError(false);
    setdobError(false);
    setmobilenumberError(false);
    setmobilenumberLengthError(false);
    setemailError(false);
    setemailFormetError(false);
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);

    if (field === "date") {
      const list = [...vaccineDoseList];
      list[index]["doseDate"] = e === null ? null : moment(e);
      setvaccineDoseList(list);
    } else if (e.target.value && field === "vaccineName") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setvaccineDoseList(list);
    } else if (field === "lot") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...vaccineDoseList];
      list[index][name] = value.replaceAll("''", "'");
      setvaccineDoseList(list);
    } else if (field === "country") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setstateLoaderIndex(`state` + index);
      setvaccineDoseList(list);
      setStateloader(true);
      countryList.map((item) => {
        if (item.countryCode == value) {
          let data = {
            countryId: item.id,
          };
          API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              const list = [...vaccineDoseList];
              list[index]["state"] = "";
              let allEntries = Object.entries(res.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["stateList"] = sortedEntries;
              setStateloader(false);
            }
          });
        }
      });
      setvaccineDoseList(list);
    } else if (field === "state") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setcountyLoaderIndex(`county` + index);
      setvaccineDoseList(list);
      setcountyLoader(true);
      // let check1 = e.target.value.split(",");
      list[index]["stateList"].map((item) => {
        if (item[1].stateCode == value) {
          let data = {
            stateId: item[1].id,
          };

          API.GET_BVI_CITYLIST(data, (res) => {
            if (res.statusCode === 200) {
              list[index]["county"] = "";
              list[index]["city"] = "";
              let allEntries = Object.entries(res.info.cities);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["cityList"] = sortedEntries;
              setcountyLoader(false);
            }

          });
        }
      });

    } else if (field === "county") {
      const { name, value } = e.target;
      const list = [...vaccineDoseList];
      list[index][name] = value;
      setvaccineDoseList(list);
    } else if (field === "city") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...vaccineDoseList];
      list[index][name] = value.replaceAll("''", "'");
      setvaccineDoseList(list);
    } else if (e.target.value && field === "location") {
      const { name, value } = e.target;
      let labidvalue = name.split("?")[0];
      let labname = name.split("?")[1];
      const lablocation = labName.filter((item) => {
        return item.labId == value;
      });
      const list = [...vaccineDoseList];
      list[index][labidvalue] = value;
      list[index][labname] = lablocation[0].organizationName;
      setvaccineDoseList(list);
    } else if (field === "otherLabName") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...vaccineDoseList];
      list[index][name] = value.replaceAll("''", "'");
      setvaccineDoseList(list);
    }
  };

  //===Add new dose functionalities===//
  const handleAddClick = (e) => {
    setfirstNameError(false);
    setlastNameError(false);
    setdobError(false);
    setmobilenumberError(false);
    setmobilenumberLengthError(false);
    setemailError(false);
    setemailFormetError(false);
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setcountryError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);

    e.preventDefault();
    let arr = vaccineDoseList.map((item) => {
      return {
        vaccineName: item.vaccineName,
        doseDate: moment(item.doseDate).format("yyyy-MM-DD"),
        location: item.location,
        labId: item.labId,
        lot: item.lot.trim(),
        country: item.country,
        state: item.state,
        county: item.county,
        city: item.city,
        cityList: item.cityList,
        otherLabName: item.location === "Other" ? item.otherLabName.trim() : "",
        vaccinationInfoId: item.vaccinationInfoId,
        stateList: item.stateList,
        newDose: item.newDose,
        showRedFlag: item.showRedFlag,
      };
    });

    let filter_data = arr.filter(function (x) {
      if (x.vaccineName != "") {
        setvaccinationError(false);
        return true;
      } else if (x.doseDate != "Invalid date" || x.doseDate != null) {
        setvaccinationdateError(false);
        return true;
      } else if (x.lot.trim() != "") {
        setlotError(false);
        return true;
      }
      else if (x.country != "") {
        setcountryError(false);
        return true;
      }
      else if (x.state != "") {
        setstateError(false);
        return true;
      } else if (x.cityList.length && x.county != "") {
        setcountyError(false);
        return true;
      } else if (!x.cityList.length && x.city != "") {
        setcityError(false);
        return true;
      } else if (x.labId != " ") {
        setlocationError(false);
        return true;
      } else if (x.location != "") {
        return true;
      } else if (x.location != "Other" && x.otherLabName.trim() != "") {
        setOtherlocationError(false);
        return true;
      }
    });
    let Checkfilter_data = arr.filter(function (x) {
      if (x.vaccineName == "") {
        setvaccinationError(true);
        return true;
      } else if (x.doseDate === "Invalid date" || x.doseDate === null) {
        setvaccinationdateError(true);
        return true;
      } else if (x.lot.trim() == "") {
        setlotError(true);
        return true;
      }
      else if (x.country == "") {
        setcountryError(true);
        return true;
      }
      else if (x.state == "") {
        setstateError(true);
        return true;
      } else if (x.cityList.length && x.county == "") {
        setcountyError(true);
        return true;
      } else if (!x.cityList.length && x.city == "") {
        setcityError(true);
        return true;
      } else if (x.labId == "") {
        setlocationError(true);
        return true;
      } else if (x.location == "") {
        return true;
      } else if (x.location == "Other" && x.otherLabName.trim() == "") {
        setOtherlocationError(true);
        return true;
      }
    });
    if (Checkfilter_data.length === 0) {
      setvaccineDoseList([
        ...arr,
        {
          vaccineName: "",
          doseDate: null,
          lot: "",
          state: "",
          labId: 32,
          location: "Other",
          otherLabName: "",
          vaccinationInfoId: 0,
          country: "",
          county: "",
          city: "",
          stateList: [],
          cityList: [],
          newDose: true,
        },
      ]);

      setvaccinationError(false);
      setvaccinationdateError(false);
      setlotError(false);
      setcountryError(false);
      setstateError(false);
      setcountyError(false);
      setcityError(false);
      setlocationError(false);
      setOtherlocationError(false);
    }
  };

  //------onClick Form Update--------//
  const updateData = (e, patientId) => {
    e.preventDefault();
    setfirstNameError(false);
    setlastNameError(false);
    setdobError(false);
    setmobilenumberError(false);
    setmobilenumberLengthError(false);
    setemailError(false);
    setemailFormetError(false);
    setvaccinationError(false);
    setvaccinationdateError(false);
    setlotError(false);
    setcountryError(false);
    setstateError(false);
    setcountyError(false);
    setcityError(false);
    setlocationError(false);
    setOtherlocationError(false);

    let arr = vaccineDoseList.map((item) => {
      return {
        vaccinationType: item.vaccineName,
        date: moment(item.doseDate).format("yyyy-MM-DD"),
        location: item.location,
        labId: item.labId,
        lot: item.lot.trim(),
        country: item.country,
        state: item.state,
        county: item.county,
        city: item.city,
        cityList: item.cityList,
        otherLabName: item.location === "Other" ? item.otherLabName.trim() : "",
        vaxInfoId: item.vaccinationInfoId,
      };
    });

    if (arr.length === 0) {
      toast.error("Please add atleast one dose before save");
    }
    let filter_data = arr.filter(function (x) {
      if (x.vaccinationType != "") {
        return true;
      } else if (x.date != "Invalid date" || x.date != null) {
        return true;
      } else if (x.lot.trim() != "") {
        return true;
      } else if (x.country != "") {
        setcountryError(false);
        return true;
      } else if (x.state != "") {
        return true;
      } else if (x.cityList.length && x.county != "") {
        setcountyError(false);
        return true;
      } else if (!x.cityList.length && x.city != "") {
        setcityError(false);
        return true;
      } else if (x.labId != " ") {
        return true;
      } else if (x.location != "") {
        return true;
      } else if (x.location != "Other" && x.otherLabName.trim() != "") {
        return true;
      }
    });

    const newFilterArr = filter_data.map(({ cityList, ...rest }) => {
      return rest;
    });

    let Checkfilter_data = arr.filter(function (x) {
      if (x.vaccinationType == "") {
        return true;
      } else if (x.date === "Invalid date" || x.date === null) {
        return true;
      } else if (x.lot.trim() == "") {
        return true;
      } else if (x.country == "") {
        setcountryError(true);
        return true;
      } else if (x.state == "") {
        return true;
      } else if (x.cityList.length && x.county == "") {
        setcountyError(true);
        return true;
      } else if (!x.cityList.length && x.city == "") {
        setcityError(true);
        return true;
      } else if (x.labId == "") {
        return true;
      } else if (x.location == "") {
        return true;
      } else if (x.location == "Other" && x.otherLabName.trim() == "") {
        return true;
      }
    });

    if (details.firstName.trim() === "") {
      setfirstNameError(true);
      firstnameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (details.lastName.trim() === "") {
      setlastNameError(true);
      lastnameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (dobDate === "Invalid date" || dobDate === null) {
      setdobError(true);
      dobRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      setmobilenumberError(true);
      mobileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (newnumber.slice(dialCode.length).length < 8) {
      setmobilenumberLengthError(true);
      mobileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (details.email.trim() === "") {
      setemailError(true);
      emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (details.email.trim() !== "N/A" && (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(details.email.trim()) &&
      details.email.trim() != "")) {
      setemailFormetError(true);
      emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (vaccineDoseList.length) {
      for (let i = 0; i < vaccineDoseList.length; i++) {
        if (vaccineDoseList[i].vaccineName === "") {
          setvaccinationError(true);
          if (vaccinenameRef && vaccinenameRef.current) {
            vaccinenameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (
          vaccineDoseList[i].doseDate === "Invalid date" ||
          vaccineDoseList[i].doseDate === null
        ) {
          setvaccinationdateError(true);
          if (vaccinedateRef && vaccinedateRef.current) {
            vaccinedateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].lot.trim() === "") {
          setlotError(true);
          if (lotRef && lotRef.current) {
            lotRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].country === "") {
          setcountryError(true);
          if (countryRef && countryRef.current) {
            countryRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].state === "") {
          setstateError(true);
          if (stateRef && stateRef.current) {
            stateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].cityList.length && vaccineDoseList[i].county === "") {
          setcountyError(true);
          if (countyRef && countyRef.current) {
            countyRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (!vaccineDoseList[i].cityList.length && vaccineDoseList[i].city === "") {
          setcityError(true);
          if (cityRef && cityRef.current) {
            cityRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (vaccineDoseList[i].labId === "") {
          setlocationError(true);
        } else if (
          vaccineDoseList[i].labId === 32 &&
          vaccineDoseList[i].otherLabName.trim() === ""
        ) {
          setOtherlocationError(true);
          if (otherLabRef && otherLabRef.current) {
            otherLabRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
      if (Checkfilter_data.length === 0) {
        console.log(">>>>>>> API call >>>>>>>>>>");
        setloader(true);
        let data = {
          patientId: patientId,
          firstName: details.firstName,
          lastName: details.lastName,
          middleName: middleName,
          email: details.email,
          dob: moment(dobDate).format("yyyy-MM-DD"),
          mobileNumber: newnumber.includes("+") ? newnumber : "+" + newnumber,
          status,
          sendTextToMobile,
          vaxInfos: newFilterArr,
          idsToDelete: removeDoseIds
        };
        console.log("data", data)
        API.EXIST_UPDATE_FORM_API(data, (res) => {
          if (res.statusCode === 200) {
            toast.success("Patient details updated successfully");
            setExpandedId(-1);
            window.scrollTo(0, 0);
            getlist();
          } else {
            setloader(false);
            toast.error(res.info);
          }
        });
      }
    }
  };

  //------onSelect--------//
  const onSelect = (id, keyvalue) => {
    handleExpandClick(keyvalue);
    if (id) {
      setDetails({
        ...details,
        ...dataItems,
      });
      setremoveDoseIds([]);
      setvaccineDoseList(vaccinearray);
      setNote("");
      setNumber(dataItems.mobileNumber);
      setdobDate(moment(dataItems.dob));
    }
  };

  //------onClick ADD--------//
  const AddNote = (e, id) => {
    e.preventDefault();
    const newItem = {
      patientId: id,
      notes: note.trim(),
    };

    if (newItem.notes != "") {
      API.EXIST_ADD_NOTE_API(newItem, (res) => {
        if (res.statusCode === 200) {
          toast.success(res.info);
        } else {
          toast.error(res.info);
        }
      });
      const newData = [...notesData, newItem];
      setNotesData(newData);
      setNote("");
    } else {
      toast.error("Please enter the note");
    }
  };

  const handleRemoveClick = (index, newDose) => {
    if (newDose == true) {
      confirmAlert({
        title: 'Delete Dose',
        message: `Are you sure you want to delete this dose?`,
        buttons: [
          {
            label: 'Confirm',
            onClick: () => RemoveDummyDoseconfirmfun(index)
          },
          {
            label: 'Cancel',
            onClick: () => Dosecancelfun()
          }
        ],
      })
    } else {
      confirmAlert({
        title: 'Delete Dose',
        message: `Are you sure you want to delete this dose?`,
        buttons: [
          {
            label: 'Confirm',
            onClick: () => RemoveDBDoseconfirmfun(newDose, index)
          },
          {
            label: 'Cancel',
            onClick: () => Dosecancelfun()
          }
        ],
      })
    }
  };

  const RemoveDummyDoseconfirmfun = (index) => {
    const list = [...vaccineDoseList];
    list.splice(index, 1);
    setvaccineDoseList(list);
  }

  const Dosecancelfun = () => {
    console.log("cancel clicked")
  }

  const RemoveDBDoseconfirmfun = (newDoseVal, formIDvalue) => {
    console.log("===API CALL CAN RUN===");
    let data = {
      type: "existing",
      id: newDoseVal
    };
    const itratevalue = [...removeDoseIds, newDoseVal];
    setremoveDoseIds(itratevalue);
    console.log('itratevalue', itratevalue);

    const list = [...vaccineDoseList];
    list.splice(formIDvalue, 1);
    setvaccineDoseList(list);

    // API.DELETE_DOSE(data, (res) => {
    //   if (res.statusCode === 200) {
    //     toast.success("Dose deleted successfully");
    //     window.scrollTo(0, 0);
    //     getlist();      
    //   } else {
    //     toast.error(res.info);
    //   }
    // });
  }

  //===Status update functionalities===//
  const alertbtn = (value) => {
    confirmAlert({
      title: 'Confirm to status approval',
      message: `Are you sure?`,
      childrenElement: () => <div>You want to change the status as <span className="Status_name">{value}</span></div>,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => confirmfun(value)
        },
        {
          label: 'Cancel',
          onClick: () => cancelfun()
        }
      ],
    })
  };

  const confirmfun = (val) => {
    setloader(true);
    SetstatusValue(val);
    let data = {
      status: val,
      patientId: patientId,
      BVICode: "BVI",
    };
    console.log("data", data)
    API.EXIST_UPDATE_STATUS_API(data, (res) => {
      if (res.statusCode === 200) {
        setExpandedId(-1);
        getlist();
        toast.success("Status updated successfully");
      }
      else {
        setExpandedId(-1);
        getlist();
        toast.error(res.errorMessage);
      }
    });

  }

  const cancelfun = () => {
    console.log("cancel clicked")
  }


  const handleStatus = (e) => {
    if (e.target.value !== "") {
      alertbtn(e.target.value);
    }
  };

  const [getImg, setImg] = React.useState("");

  const handleOpen = (imgURL) => {
    if (imgURL != "") {
      setImg(imgURL);
      modalsetOpen(true);
    }
  };
  const handleClose = () => {
    modalsetOpen(false);
  };

  const body = (
    <div
      style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      className="paperModal"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <div className="closeHolder" onClick={handleClose}>
        <img src={Closeicon} style={{ width: "30px" }} alt="close-icon" />
      </div>
      {getImg.split(".").pop().split(/\#|\?/)[0].toLowerCase() === "pdf" ? (
        <div className="pdfViewModal">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div>
              <Viewer defaultScale={0.5} fileUrl={getImg} />
            </div>
          </Worker>
        </div>
      ) : (
        <div className="modal_image_preview">
          <ReactPanZoom image={getImg} alt="modal-view" />
          {/* <img
            src={getImg}
            alt="modal-view"
            draggable="false"
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          /> */}
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <TableRow className="table_row">
        <TableCell
          onClick={() => onSelect(patientId, keyvalue)}
          style={{ cursor: "pointer" }}
        >
          {(expandedId === keyvalue) == false ? (
            <NavigateNextIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </TableCell>
        <TableCell
          className="table_row_size"
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {formSubmittedOn ? moment(formSubmittedOn).format("MM-DD-yyyy") : ""}
        </TableCell>

        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          component="th"
          scope="row"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {firstName}
        </TableCell>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {lastName}
        </TableCell>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {moment(dob).format("MM-DD-yyyy")}
        </TableCell>

        <TableCell className="table_row_size" align="center">
          <FormControl fullWidth variant="outlined" className="form-dropdown">
            <Select
              native
              value={StatusValue ? StatusValue : status}
              onChange={handleStatus}
              className="select_res"
              inputProps={{
                name: "Status",
              }}
            >
              <option aria-label="None" value="" />

              <option value={"Not Submitted"}>
                Not Submitted
              </option>
              <optgroup label="- Approval Statuses -"></optgroup>
              <option value={"Document Approval - L1"}>Document Approval - L1</option>
              <option value={"Registry Approval - L2"}>
                Registry Approval - L2
              </option>
              <optgroup label="- Review Statuses -"></optgroup>
              <option value={"Pending Review"}>Pending Review</option>
              <option value={"Pending ID/Vax Verification"}>
                Pending ID/Vax Verification
              </option>
              <optgroup label="- Validation Statuses -"></optgroup>
              <option value={"Requires ID Verification"}>
                Requires ID Verification
              </option>
              <option value={"Requires Vax Card Verification"}>
                Requires Vax Card Verification
              </option>
              <option value={"Requires ID and Vax Card Verification"}>
                Requires ID and Vax Card Verification
              </option>
              {/* <option value={"Requires Clinic Verification"}>
                Requires Clinic Verification
              </option> */}
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expandedId === keyvalue} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <form autoComplete="off">
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sm={12} ref={firstnameRef}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="First name *"
                        type="text"
                        value={details.firstName}
                        name="firstName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                        className="form_textfield"
                      />
                      {firstNameError === true &&
                        details.firstName.trim() === "" ? (
                        <div className="errorText mt">
                          Please enter the firstname
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} ref={lastnameRef}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Last name *"
                        type="text"
                        value={details.lastName}
                        name="lastName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                        className="form_textfield"
                      />

                      {lastNameError === true &&
                        details.lastName.trim() === "" ? (
                        <div className="errorText mt">
                          Please enter the lastName
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} ref={dobRef}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className=" form-dropdown date_search_field"
                          style={{
                            width: "100%",
                          }}
                          name="dob"
                          clearable
                          value={dobDate}
                          onChange={(date) => handleDobChange(date)}
                          format="MM/dd/yyyy"
                          label="DOB (MM/DD/YYYY)"
                          maxDate={new Date()}
                          required
                          autoOk
                        />
                      </MuiPickersUtilsProvider>

                      {dobError === true && dobDate === null ? (
                        <div className="errorText mt">
                          Please enter the date of birth
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} ref={mobileRef}>
                      <InputLabel
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          paddingBottom: "0.3em",
                          fontSize: "13px",
                        }}
                      >
                        Mobile number *
                      </InputLabel>
                      <PhoneInput
                        country={"us"}
                        // onlyCountries={["us"]}
                        // countryCodeEditable={false}
                        required
                        name="mobileNumber"
                        // disableDropdown={true}
                        onChange={mobileNumberChange}
                        value={newnumber}
                      />
                      {mobilenumberError === true ? (
                        <div className="errorText mt">
                          Please enter the Mobile Number
                        </div>
                      ) : null}
                      {mobilenumberLengthError === true ? (
                        <div className="errorText mt">
                          Please enter the full digit mobile number
                        </div>
                      ) : null}
                    </Grid>

                    <Grid className="d-flex" style={{ justifyContent: "flex-start" }} item xs={12} md={4} sm={12}>
                      <Switch
                        checked={sendTextObj.sendTextToMobile}
                        onChange={onChangeSendText}
                        name="sendTextToMobile"
                      />
                      <span
                        style={{
                          color: "#808080",
                          marginLeft: "10px",
                          fontSize: "15px",
                        }}
                      >
                        Can this number receive text messages?
                      </span>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12} ref={emailRef}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Email *"
                        type="text"
                        value={details.email}
                        name="email"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                        className="form_textfield"
                      />
                      {emailError === true && details.email.trim() === "" ? (
                        <div className="errorText mt">
                          Please enter the email
                        </div>
                      ) : null}
                      {emailFormetError === true ? (
                        <div className="errorText mt">
                          Please enter the valid email
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} className="my">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <div className="span_text">Photo ID:</div>
                        <div>
                          {details.licenceUrl ? (
                            ["jpeg", "png", "jpg", "heif", "pdf"].includes(
                              details.licenceUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase()
                            ) ? (
                              details.licenceUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === "pdf" ? (
                                <div>
                                  <Button
                                    style={{
                                      background: "#f07167",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      padding: "3px 8px",
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.licenceUrl);
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  className="preview-img"
                                  alt="previw-img"
                                  src={details.licenceUrl}
                                  onClick={(e) => {
                                    handleOpen(details.licenceUrl);
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )
                            ) : (
                              // <a
                              //   style={{ color: "#f07167" }}
                              //   href={details.licenceUrl}
                              //   target="_blank"
                              // >
                              //   Open the file
                              // </a>
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} className="my">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <div className="span_text">VAX Record Photo:</div>
                        <div>
                          {details.cardUrl ? (
                            ["jpeg", "png", "jpg", "heif", "pdf"].includes(
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase()
                            ) ? (
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === "pdf" ? (
                                <div>
                                  <Button
                                    style={{
                                      background: "#f07167",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      padding: "3px 8px",
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.cardUrl);
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  className="preview-img"
                                  alt="previw-img"
                                  src={details.cardUrl}
                                  onClick={(e) => {
                                    handleOpen(details.cardUrl);
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )
                            ) : (
                              // <a
                              //   style={{ color: "#f07167" }}
                              //   href={details.cardUrl}
                              //   target="_blank"
                              // >
                              //   Open the file
                              // </a>
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Grid>
                    {vaccineDoseList.map((dose, i) => {
                      return (
                        <>
                          <Paper elevation={4} style={{ width: "100%", padding: "20px", margin: "5px 0px" }}>
                            <Grid container spacing={3}>
                              {
                                dose.showRedFlag == true ? (
                                  <Grid className="flagClass" item xs={12} md={8} sm={12}>
                                    <div className="redFlagIcon" style={{ paddingLeft: "12px" }} ><span className="redFlagIcon"><AssistantPhotoIcon style={{ color: "red", padding: "0px", marginLeft: "-4px" }} /></span></div>
                                    <div className="redFlagText"><p style={{ paddingRight: "15px" }}>This dose is not recorded in the immunization registry</p></div>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} md={8} sm={12} style={{ padding: "0px" }}>
                                  </Grid>
                                )
                              }
                              {showRemoveButton == true || dose.newDose == true ?
                                <>
                                  <Grid item xs={12} md={4} sm={12} style={{ padding: "0px" }}>
                                    <div className="removeDose">
                                      <span className="removebtn"
                                        onClick={() => handleRemoveClick(i, dose.newDose ? dose.newDose : dose.vaccinationInfoId, keyvalue)}
                                      >
                                        <DeleteIcon
                                          style={{ width: "20px", marginRight: "5px" }}
                                        />Remove
                                      </span>
                                    </div>
                                  </Grid>
                                </>
                                : (
                                  <Grid item xs={12} md={4} sm={12} style={{ padding: "0px" }}>
                                  </Grid>
                                )}

                              <Grid item xs={12} md={6} sm={12} ref={vaccinenameRef}>
                                <FormControl
                                  fullWidth
                                  variant="standard"
                                  className="form-dropdown"
                                >
                                  <InputLabel>
                                    <span style={{ marginRight: "6px" }}>
                                      {i + 1}
                                      {i + 1 == 1
                                        ? "st"
                                        : i + 1 == 2
                                          ? "nd"
                                          : i + 1 == 3
                                            ? "rd"
                                            : "th"}
                                    </span>Vaccination Name *
                                  </InputLabel>
                                  <Select
                                    name="vaccineName"
                                    value={dose.vaccineName}
                                    onChange={(e) =>
                                      handleInputChange(e, i, "vaccineName")
                                    }
                                    required
                                  >
                                    {vaccineNames.map((item) => (
                                      <MenuItem
                                        key={item.vaccineName}
                                        value={item.vaccineName}
                                      >
                                        {item.vaccineName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                {vaccinationError === true &&
                                  dose.vaccineName === "" ? (
                                  <div className="errorText mt">
                                    Please select Vaccination name
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12} ref={vaccinedateRef}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    className="form-dropdown date_search_field"
                                    style={{
                                      width: "100%",
                                    }}
                                    clearable
                                    name="date"
                                    value={dose.doseDate}
                                    onChange={(e) =>
                                      handleInputChange(e, i, "date")
                                    }
                                    format="MM/dd/yyyy"
                                    label="Vaccination date (MM/DD/YYYY)"
                                    maxDate={new Date()}
                                    required
                                    autoOk
                                  />
                                </MuiPickersUtilsProvider>
                                {vaccinationdateError === true &&
                                  dose.doseDate === null ? (
                                  <div className="errorText mt">
                                    Please select Vaccination date
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12} ref={lotRef}>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  label="Lot # *"
                                  type="text"
                                  value={dose.lot}
                                  name="lot"
                                  onChange={(e) => handleInputChange(e, i, "lot")}
                                  className="form_textfield"
                                />
                                {lotError === true && dose.lot.trim() === "" ? (
                                  <div className="errorText mt">
                                    Please enter the LOT no
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12} ref={countryRef}>
                                <FormControl
                                  className="form-dropdown"
                                  fullWidth
                                  variant="standard"
                                >
                                  <InputLabel>Country *</InputLabel>
                                  <Select
                                    name="country"
                                    value={dose.country}
                                    onChange={(e) =>
                                      handleInputChange(e, i, "country")
                                    }
                                    required
                                  >
                                    {countryList.map((item) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.countryCode}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {countryError === true && dose.country === "" ? (
                                  <div className="errorText mt">
                                    Please select the Country
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                              {`state` + i == stateLoaderIndex && Stateloader == true ? (
                                <Grid item xs={12} md={4} sm={12} className="stateLoader">
                                  <div class="loader1">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                </Grid>
                              ) : (
                                <Grid item xs={12} md={4} sm={12} ref={stateRef}>
                                  <FormControl
                                    className="form-dropdown"
                                    fullWidth
                                    variant="standard"
                                  >
                                    <InputLabel>Location *</InputLabel>
                                    <Select
                                      name="state"
                                      value={dose.state}
                                      onChange={(e) =>
                                        handleInputChange(e, i, "state")
                                      }
                                      required
                                    >
                                      {dose.stateList &&
                                        dose.stateList.map((item) => (
                                          <MenuItem
                                            value={
                                              item[1].stateCode
                                            }
                                          >
                                            {item[0]}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                  {stateError === true && dose.state === "" ? (
                                    <div className="errorText mt">
                                      Please select the location
                                    </div>
                                  ) : null}
                                </Grid>
                              )}

                              {`county` + i == countyLoaderIndex && countyLoader == true ? (
                                <Grid item xs={12} md={4} sm={12} className="stateLoader">
                                  <div class="loader1">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                </Grid>

                              ) : (<>
                                {dose.cityList && dose.cityList.length ? <><Grid item xs={12} md={4} sm={12} ref={countyRef}>
                                  <FormControl
                                    className="form-dropdown"
                                    fullWidth
                                    variant="standard"
                                  >
                                    <InputLabel>County/District *</InputLabel>
                                    <Select
                                      name="county"
                                      value={dose.county}
                                      onChange={(e) => handleInputChange(e, i, "county")}
                                      required
                                    >
                                      {dose.cityList && dose.cityList.map((item) => (
                                        <MenuItem value={item[0]}>{item[0]}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {countyError === true && dose.county === "" ? (
                                    <div className="errorText">
                                      Please select the County
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Grid></> : <Grid item xs={12} md={4} sm={12} ref={cityRef}>
                                  <TextField
                                    fullWidth
                                    className="form_textfield"
                                    label="City/Estate *"
                                    variant="standard"
                                    name="city"
                                    inputProps={{ maxLength: 40, autoComplete: "none" }}
                                    value={dose.city}
                                    onChange={(e) => handleInputChange(e, i, "city")}
                                  />
                                  {cityError === true && dose.city === "" ? (
                                    <div className="errorText">
                                      Please enter the City
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Grid>}</>
                              )
                              }

                              {/* <Grid item xs={12} md={6} sm={12}>
                            <FormControl
                              variant="standard"
                              className="form-dropdown"
                              fullWidth
                            >
                              <InputLabel>Site *</InputLabel>
                              <Select
                                name="labId?location"
                                value={dose.labId}
                                onChange={(e) =>
                                  handleInputChange(e, i, "location")
                                }
                                required
                              >
                                {labName.map((item) => (
                                  <MenuItem key={item.labId} value={item.labId}>
                                    {item.organizationName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {locationError === true && dose.labId === "" ? (
                              <div className="errorText mt">
                                Please select the site.
                              </div>
                            ) : null}
                          </Grid> */}
                              {dose.labId === 32 && (
                                <Grid item xs={12} md={4} sm={12} ref={otherLabRef}>
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    className="form_textfield"
                                    value={dose.otherLabName}
                                    name="otherLabName"
                                    label="Please specify site location *"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "otherLabName")
                                    }
                                    inputProps={{
                                      maxLength: 30,
                                      autoComplete: "none",
                                    }}
                                  />

                                  {OtherlocationError === true &&
                                    dose.otherLabName.trim() === "" ? (
                                    <div className="errorText mt">
                                      Please enter the site name
                                    </div>
                                  ) : null}
                                </Grid>
                              )}

                              <Grid item xs={12} md={6} sm={12}>
                                <span className="span_text">Site phone:</span>
                                {dose.sitePhoneNumber}
                              </Grid>

                            </Grid>
                          </Paper>
                        </>
                      );
                    })}
                    <Grid item xs={12} md={6} sm={12}>
                      <span className="span_text">Notes:</span>
                      <FormControl fullWidth className="form-dropdown">
                        <InputLabel htmlFor="my-input">Add notes</InputLabel>
                        <Input
                          label="Add notes"
                          type="text"
                          value={note}
                          name="Add notes"
                          onChange={handleNoteChange}
                          endAdornment={
                            <IconButton
                              type="submit"
                              onClick={(e) => AddNote(e, patientId)}
                            >
                              <AddBoxIcon style={{ color: "#4cd3c5" }} />
                            </IconButton>
                          }
                        />
                      </FormControl>
                      {notesData.length !== 0 && (
                        <div
                          id="list"
                          style={{
                            height: "200px",
                          }}
                        >
                          <ScrollableFeed>
                            {notesData.map((noteitems) => {
                              return (
                                <ListItem className="listItems">
                                  <ListItemText className="listItems_data">
                                    {noteitems.note
                                      ? noteitems.note
                                      : noteitems.notes}
                                  </ListItemText>
                                </ListItem>
                              );
                            })}
                          </ScrollableFeed>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                      <span className="span_text">Status Update:</span>
                      <div
                        style={{
                          marginTop: "10px",
                          height: "200px",
                        }}
                      >
                        <ScrollableFeed>
                          {dataItems.audits.map((auditItem) => {
                            return (
                              <ListItem className="listItems">
                                <ListItemText className="listItems_data">
                                  {auditItem.adminName} updated{" "}
                                  {auditItem.status} at{" "}
                                  {moment(auditItem.date).format("MM-DD-yyyy")}
                                </ListItemText>
                              </ListItem>
                            );
                          })}
                        </ScrollableFeed>
                      </div>
                    </Grid>
                  </Grid>
                  {status == "Approved/VAMS Updated" ||
                    status == "Approved/VAMS Pending" ? (
                    <div></div>
                  ) : (
                    <Grid item xs={12} md={12} sm={12}>
                      <div className="d-flex my">
                        <div>
                          <button onClick={handleAddClick} className="srchbtn">
                            Add Dose
                          </button>
                        </div>
                        <div className="mx"></div>
                        <div>
                          <button
                            className="srchbtn"
                            onClick={(e) => updateData(e, patientId)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={Modalopen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );
}

export default Row;
