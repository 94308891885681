// Auth
export const LOGIN_URL = process.env.REACT_APP_API_URL + "vax/admin/login";
export const VALIDATEOTP_URL =
  process.env.REACT_APP_API_URL + "vax/admin/otp/validate";
// export const RESENDOTP_URL = process.env.REACT_APP_API_URL + 'patient/exp/otp/resend'
export const REFRESHTOKEN_URL =
  process.env.REACT_APP_API_URL + "vax/refresh/token";

export const GET_COUNTIES_URL =
  process.env.REACT_APP_API_URL + "public/get/state/counties";
export const GET_ORG_URL =
  process.env.REACT_APP_API_URL + "public/get/organizations";
export const GET_VACCINATION_URL =
  process.env.REACT_APP_API_URL + "bvi/get/vaccination/configs";


//New Patient URL
export const GETNEWPATIENT_URL =
  process.env.REACT_APP_API_URL + "bvi/vax/get/all/vax/public/form";
export const UPDATE_STATUS_URL =
  process.env.REACT_APP_API_URL + "bvi/vax/update/status";
export const UPDATE_FORM_URL =
  process.env.REACT_APP_API_URL + "bvi/vax/update/form";
export const ADD_NOTE_URL = process.env.REACT_APP_API_URL + "bvi/vax/add/note";

//Exist Patient URL
export const GETEXISTPATIENT_URL =
  process.env.REACT_APP_API_URL + "bvi/vax/get/all/vax/patient";
export const EXIST_UPDATE_STATUS_URL =
  process.env.REACT_APP_API_URL + "bvi/vax/update/patient/status";
  export const EXIST_UPDATE_FORM_URL =
  process.env.REACT_APP_API_URL + "bvi/vax/update/patient"; 
  export const EXIST_ADD_NOTE_URL = process.env.REACT_APP_API_URL + "bvi/vax/add/patient/note";


  //MissingDose URL   
export const GETMISSINGDOSEPATIENT_URL =
process.env.REACT_APP_API_URL + "bvi/vax/get/all/vax/patient/missing/dose";
export const MISSINGDOSE_UPDATE_STATUS_URL =
process.env.REACT_APP_API_URL + "bvi/vax/update/missing/dose/status";
export const MISSINGDOSE_UPDATE_FORM_URL =
process.env.REACT_APP_API_URL + "bvi/vax/update/missing/doses";
export const MISSINGDOSE_ADD_NOTE_URL = process.env.REACT_APP_API_URL + "bvi/vax/add/missingDose/note";

export const GET_BVI_COUNTRYLIST =
  process.env.REACT_APP_API_URL + "bvi/country/list";
export const GET_BVI_STATELIST =
  process.env.REACT_APP_API_URL + "bvi/state/list";
  export const GET_BVI_CITYLIST = process.env.REACT_APP_API_URL + "bvi/city/list";
export const GET_TEST_FORM_DROPDOWN_URL =
  process.env.REACT_APP_API_URL + "bvi/dropdown/list";
//Delete Dose URL
export const DELETE_DOSE = process.env.REACT_APP_API_URL + "bvi/vax/delete/dose"; 

//Add Patient URL   
export const ADD_VAX_PATIENT =
  process.env.REACT_APP_API_URL + "bvi/add/vax/patient";

  //Download Patient PDF URL   
export const DOWNLOAD_PDF_FILE =
process.env.REACT_APP_API_URL + "bvi/vax/download/pdf";

//Resend Notification URL   
export const RESEND_NOTIFICATION =
  process.env.REACT_APP_API_URL + "bvi/vax/resend/notification";

//Delete photoID and vaxcard photos
export const DELETE_PHOTO_VAXID =
process.env.REACT_APP_API_URL + "bvi/delete/image";
