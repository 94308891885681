import axios from "axios";
import * as API_URL from "../API-URL/index.js";

// Auth login
export function LoginAPI(data, res) {
  axios
    .post(API_URL.LOGIN_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// Auth Validate OTP
export function ValidateOTPAPI(data, res) {
  axios
    .get(API_URL.VALIDATEOTP_URL + "?otp=" + data.otp)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
    });
}

//Get Counties API
export function GetCounties_API(res) {
  axios
    .get(API_URL.GET_COUNTIES_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
//Get Vaccination names list
export function GetOrg_API(res) {
  axios
    .get(API_URL.GET_ORG_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export function GetVaccination_API(res) {
  axios
    .get(API_URL.GET_VACCINATION_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
// Auth Refresh Token
export function RefreshTokenAPI(data, res) {
  axios
    .get(API_URL.REFRESHTOKEN_URL + "?refreshToken=" + data.refreshToken, {
      headers: {
        Authorization:
          "61c32291-e243-4c49-9d16-532d44f04d9a6ee023b4-4823-4ff0-9fc4-0f3797e7c1c1",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//New Patient API
export function GETNEWPATIENT_URL(data, res) {
  axios
    .post(API_URL.GETNEWPATIENT_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Exist Patient API
export function GETEXISTPATIENT_URL(data, res) {
  axios
    .post(API_URL.GETEXISTPATIENT_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//MissingDose Patient API
export function GETMISSINGDOSEPATIENT_API(data, res) {
  axios
    .post(API_URL.GETMISSINGDOSEPATIENT_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// NEW Add Note
export function ADD_NOTE_URL(data, res) {
  axios
    .post(API_URL.ADD_NOTE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// Exist Add Note
export function EXIST_ADD_NOTE_API(data, res) {
  axios
    .post(API_URL.EXIST_ADD_NOTE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// Missing Dose Add Note
export function MISSINGDOSE_ADD_NOTE_API(data, res) {
  axios
    .post(API_URL.MISSINGDOSE_ADD_NOTE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//New Patient Update status
export function UPDATE_STATUS(data, res) {
  axios
    .post(
      API_URL.UPDATE_STATUS_URL +
        "?formId=" +
        data.formId +
        "&status=" +
        data.status +
        "&createdIn=" +
        data.BVICode,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Exist Patient Update status
export function EXIST_UPDATE_STATUS_API(data, res) {
  axios
    .post(
      API_URL.EXIST_UPDATE_STATUS_URL +
        "?patientId=" +
        data.patientId +
        "&status=" +
        data.status +
        "&createdIn=" +
        data.BVICode,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//MissingDose Patient Update status
export function MISSINGDOSE_UPDATE_STATUS_API(data, res) {
  axios
    .post(
      API_URL.MISSINGDOSE_UPDATE_STATUS_URL +
        "?missingDoseFormId=" +
        data.missingDoseFormId +
        "&missingDoseStatus=" +
        data.missingDoseStatus +
        "&createdIn=" +
        data.BVICode,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}



//New Patient UPDATE_FORM_URL API
export function UPDATE_FORM_URL(data, res) {
  axios
    .post(API_URL.UPDATE_FORM_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//EXIST UPDATE_FORM_URL API
export function EXIST_UPDATE_FORM_API(data, res) {
  axios
    .post(API_URL.EXIST_UPDATE_FORM_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}


//MissingDose UPDATE_FORM_URL API
export function MISSINGDOSE_UPDATE_FORM_API(data, res) {
  axios
    .post(API_URL.MISSINGDOSE_UPDATE_FORM_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//ADD Patient API
export function ADD_VAX_PATIENT(data, res) {
  axios
    .post(API_URL.ADD_VAX_PATIENT, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Download Vaccination pdf API
export function DOWNLOAD_PDF_FILE(data, res) {
  axios
    .get(API_URL.DOWNLOAD_PDF_FILE + "?patientID=" + data.patientId,
      {
        headers: {
          Authorization: localStorage.getItem("accessToken")
        },
      })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Resend Vaccination Link
export function RESEND_NOTIFICATION(data, res) {
  axios
    .get(API_URL.RESEND_NOTIFICATION + "?patientID=" + data.patientId,
      {
        headers: {
          Authorization: localStorage.getItem("accessToken")
        },
      })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}




//Get Countrylist API
export function GET_BVI_COUNTRYLIST(res) {
  axios
    .get(API_URL.GET_BVI_COUNTRYLIST)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get CityList API
export function GET_BVI_CITYLIST(data, res) {
  axios
    .post(API_URL.GET_BVI_CITYLIST + "?stateId=" + data.stateId, {})
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}


//Get Test Form dropdown details
export function GET_TEST_FORM_DROPDOWN_URL(res) {
  axios
    .get(API_URL.GET_TEST_FORM_DROPDOWN_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}


//Get STATElist API
export function GET_BVI_STATELIST(data, res) {
  axios
    .post(API_URL.GET_BVI_STATELIST + "?countryId=" + data.countryId, {})
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}


//DELETE DOSE LIST API
export function DELETE_DOSE(data, res) {
  axios
    .post(
      API_URL.DELETE_DOSE +
      "?type=" +
      data.type +
      "&id=" +
      data.id,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//  delete photo and vaxcard images
export function deletephotoIdandVaxcard(data, res) {
  axios
    .post(
      API_URL.DELETE_PHOTO_VAXID + '?id=' + data.id + '&from=' + data.from,
      {},
      {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response) {
        res(response.data)
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response)
    })
}

// ---------------------------------------------------------------------------------------------------------
