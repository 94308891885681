import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import lockicon from "../../Assets/lock_icon.png";
import Header from "../Header/Header";
import { useHistory } from "react-router-dom";
import * as API from "../../Service/API/index.js";

const LoginOTP = (props) => {
  //OTP States

  const history = useHistory();
  const [OTPvalue, setOTPvalue] = useState("");
  const [loader, setloader] = useState(false);
  const [OTPsuccess, setOTPsuccess] = React.useState(false);

  //OTP Error States
  const [OTPError, setOTPError] = React.useState(false);
  const [OTPResetError, setOTPResetError] = React.useState(false);

  //Onclick Functionalities
  const submitOtp = () => {
    if (OTPvalue.trim() == "") {
      setOTPError(true);
    } else {
      setloader(true);
      let data = {
        email: props.location.state.email,
        otp: OTPvalue.trim(),
      };

      console.log("Success:", data);
      API.ValidateOTPAPI(data, (res) => {
        if (res.accessToken) {
          localStorage.setItem("accessToken", res.accessToken);
          localStorage.setItem("refreshToken", res.refreshToken);
          localStorage.setItem("features", res.features);

          if (
            localStorage.getItem("features").indexOf("Vax Patient Module") >= 0
          ) {
            history.push({
              pathname: "/existing-patient",
            });
            setloader(false);
          }
          else if (
            localStorage.getItem("features").indexOf("Vax Form Module") >= 0
          ) {
            history.push({
              pathname: "/new-patient",
            });
            setloader(false);
          }
          else {
            history.push({
              pathname: "/existing-patient",
            });
            setloader(false);
          }
        } else {
          setloader(false);
          setOTPResetError(true);
        }
      });
    }
  };

  const ResendOTP = () => {
    setloader(true);
    let data = {
      messageId: props.location.state.res.messageId,
      email: props.location.state.email,
      messageCode: "",
    };
    API.LoginAPI(data, (res) => {
      if (res.statusCode == "200") {
        setloader(false);
        setOTPsuccess(true);
      } else {
        setloader(false);
      }
    });
  };

  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      submitOtp();
    }
  };
  return (
    <>
      <Header />
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="login_otp_container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <img src={lockicon} width="60px" alt="otpLock" />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography
                className="pt mx"
                variant="body1"
                style={{ fontWeight: "500" }}
              >
                Enter the verification code we just sent to your email
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} sm={12}>
              <TextField
                variant="standard"
                className="form_textfield OTP_textfield"
                id="standard-basic"
                inputProps={{
                  autoComplete: "off",
                }}
                value={OTPvalue}
                onChange={(e) => setOTPvalue(e.target.value)}
                onKeyUp={SignInC}
              />
            </Grid>
            {OTPError ? (
              <Grid item xs={12} md={12} sm={12}>
                <span className="errorText">Please enter OTP number</span>
              </Grid>
            ) : null}

            <Grid item xs={12} md={12} sm={12}>
              <Button className="resend-btn" onClick={ResendOTP}>
                <Typography className="mx" variant="body2">
                  RESEND
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              {OTPsuccess ? (
                <Typography
                  style={{ color: "green" }}
                  className="pt mx"
                  variant="body2"
                >
                  OTP sent successfully!
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <div className="submit my">
            <button className="btn1" onClick={submitOtp}>
              Submit
            </button>
          </div>
          {OTPResetError && <div className="errorText">Invalid OTP found</div>}
        </div>
      )}
    </>
  );
};

export default LoginOTP;
