import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as API from "../../Service/API/index.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Divider,
  Select,
  ListSubheader,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import Row from "./Row";

const MissingDose = () => {
  let history = useHistory();

  // Search States
  const [firstNamesearch, setfirstNamesearch] = useState("");
  const [lastNamesearch, setlastNamesearch] = useState("");
  const [searchDob, setsearchDob] = React.useState(null);
  const [FilterState, setFilterState] = React.useState("");
  const [FilterCountry, setFilterCountry] = React.useState("");
  const [stateSelect, setstateSelect] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [FilterLab, setFilterLab] = React.useState("");
  const [TravelCategory, setTravelCategory] = React.useState("");

  //Table data States
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState("formSubmittedOn");
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = React.useState("");

  const [totalcount, setTotalCount] = React.useState();
  const [loader, setloader] = React.useState(false);
  const [labName, setlabName] = React.useState([]);
  const [vaccineNames, setvaccineNames] = React.useState([]);
  const [stateName, setstateName] = React.useState("");
  const [countryName, setCountryName] = React.useState("");

  //Expand collapse states
  const [expandedId, setExpandedId] = React.useState(-1);
  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const [statusvalue, setStatusvalue] = React.useState([
    "Pending Review",
    "Requires Clinic Verification",   
    "Pending ID/Vax Verification",    
    "Requires Vax Card Verification",
  ]);

  const GetVaccinationConfigs = () => {
    API.GetOrg_API((res) => {
      setlabName(res.info);
    });
  };

  const GetVaccinationNames = () => {
    API.GetVaccination_API((res) => {
      setvaccineNames(res.info);
    });
  };

  //======Get CountryList======//
  const GetCountryList = () => {
    API.GET_BVI_COUNTRYLIST((res) => {
      setCountryList(res.info);
    });
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      getlist();
      GetVaccinationConfigs();
      GetVaccinationNames();
      GetCountryList();
    } else {
      history.push("/");
    }
  }, [page, rowsPerPage]);

  //To Get total data list
  const getlist = () => {
    setloader(true);
    setExpandedId(-1);
    let data = {
      limit: rowsPerPage,
      pageNo: page,
      sortBy: orderBy ? orderBy : "formSubmittedOn",
      status: statusvalue,
      formSubmittedOn: null,
      state: FilterState ? FilterState : null,
      labId: FilterLab ? FilterLab : null,
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      sortIn: order,
      createdApp: "BVI",
    };

    API.GETMISSINGDOSEPATIENT_API(data, (res) => {
      if (res.statusCode === 200) {
        setData(res.info);
        setTotalCount(res.totalListCount);
        setloader(false);
      } else if (res.errorMessage == "No records found") {
        // toast.error(res.errorMessage);
        setData([]);
        setloader(false);
        setTotalCount("");
      } else {
        toast.error(res.errorMessage);
        setData([]);
        setloader(false);
        setTotalCount("");
      }
    });
  };

  const handleChange = (e) => {

    if (e.target.value !== "") {

      setValues(e.target.value);
      setStatusvalue([e.target.value]);
      setPage(0);
      setData([]);
      setloader(true);
      setExpandedId(-1);
      let data = {
        limit: rowsPerPage,
        pageNo: page,
        sortBy: orderBy ? orderBy : "formSubmittedOn",
        status: [e.target.value],
        formSubmittedOn: null,
        state: FilterState ? FilterState : null,
        labId: FilterLab ? FilterLab : null,
        firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
        lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
        dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
        sortIn: order,
        createdApp: "BVI",
      };

      API.GETMISSINGDOSEPATIENT_API(data, (res) => {
        if (res.statusCode === 200) {
          setloader(false);
          setData(res.info);
          setTotalCount(res.totalListCount);
        } else {
          setloader(false);
          setData([]);
          setTotalCount("");
        }
      });

    } else {
      setValues(e.target.value);
      setStatusvalue([
        "Pending Review",
        "Requires Clinic Verification",        
        "Pending ID/Vax Verification",    
        "Requires Vax Card Verification",
      ]);
      setPage(0);
      setData([]);
      setloader(true);
      setExpandedId(-1);
      let data = {
        limit: rowsPerPage,
        pageNo: page,
        sortBy: orderBy ? orderBy : "formSubmittedOn",
        status: [
          "Pending Review",
          "Requires Clinic Verification",         
          "Pending ID/Vax Verification",    
          "Requires Vax Card Verification",
        ],
        formSubmittedOn: null,
        state: FilterState ? FilterState : null,
        labId: FilterLab ? FilterLab : null,
        firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
        lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
        dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
        sortIn: order,
        createdApp: "BVI",
      };

      API.GETMISSINGDOSEPATIENT_API(data, (res) => {
        if (res.statusCode === 200) {
          setloader(false);
          setData(res.info);
          setTotalCount(res.totalListCount);
        } else {
          setloader(false);
          setData([]);
          setTotalCount("");
        }
      });
    }

  };

  const createSortHandler = (id) => {
    setOrderBy(id);
    setOrder(order === "asc" ? "desc" : "asc");
    setloader(true);
    setData([]);
    setExpandedId(-1);
    let data = {
      limit: rowsPerPage,
      pageNo: page,
      sortBy: id,
      status: statusvalue,
      formSubmittedOn: null,
      state: FilterState ? FilterState : null,
      labId: FilterLab ? FilterLab : null,
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      sortIn: order === "asc" ? "desc" : "asc",
      createdApp: "BVI",
    };
    API.GETMISSINGDOSEPATIENT_API(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setData(res.info);
        setTotalCount(res.totalListCount);
      } else {
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };

  //---Searchby onClick ---//
  const SearchByValue = (e) => {
    if (FilterCountry !== "" && FilterState === "") {
      toast.error("Please select the location");
    } else if (
      firstNamesearch.trim() === "" &&
      lastNamesearch.trim() === "" &&
      searchDob === null &&
      FilterState === "" &&
      FilterLab === ""
    ) {
      toast.error("Please enter the search value");
    } else {
      setPage(0);
      setData([]);
      getlist();
    }
  };

  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      SearchByValue();
    }
  };

  const Resetbyvalue = () => {
    setPage(0);
    setRowsPerPage(10);
    setTotalCount("0");
    setfirstNamesearch("");
    setlastNamesearch("");
    setsearchDob(null);
    setValues("");
    setloader(true);
    setFilterState("");
    setstateSelect([]);
    setFilterLab("");
    setFilterCountry("");
    setExpandedId(-1);
    setTravelCategory("");
    setStatusvalue([
      "Pending Review",
      "Requires Clinic Verification",     
      "Pending ID/Vax Verification",    
      "Requires Vax Card Verification",
    ]);
    setOrder("desc");
    setOrderBy("formSubmittedOn");
    let data = {
      limit: rowsPerPage,
      pageNo: 0,
      sortBy: "formSubmittedOn",
      status: [
        "Pending Review",
        "Requires Clinic Verification",       
        "Pending ID/Vax Verification",    
        "Requires Vax Card Verification",
      ],
      formSubmittedOn: null,
      firstName: null,
      state: null,
      labId: null,
      lastName: null,
      dob: null,
      sortIn: "desc",
      createdApp: "BVI",
    };
    API.GETMISSINGDOSEPATIENT_API(data, (res) => {
      if (res.statusCode === 200) {
        setData(res.info);
        setTotalCount(res.totalListCount);
        setloader(false);
      } else {
        setData([]);
        setloader(false);
      }
    });
  };

  //OnChange Functionalities
  const searchDobHandleChange = (date) => {
    setsearchDob(date);
  };

  const travelCategoryOnChange = (e) => {
    setTravelCategory(e.target.value)
  }

  const FilterCountryOnchange = (e) => {
    setFilterCountry(e.target.value);
    countryList.map((item) => {
      if (item.countryCode == e.target.value) {
        let data = {
          countryId: item.id,
        };
        API.GET_BVI_STATELIST(data, (res) => {
          if (res.statusCode === 200) {
            setFilterState("");
            let allEntries = Object.entries(res.info.states);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            setstateSelect(sortedEntries);
          }
        });
      } else {
        setstateSelect([]);
      }
    });

    let countryorgname = countryList.filter((item) => {
      if (item.countryCode === e.target.value) {
        setCountryName(item.name);
      }
    });
  };

  const FilterStateChange = (e) => {
    setFilterState(e.target.value);
    let laborgname = stateSelect.filter((item) => {
      if (item[1].stateCode === e.target.value) {
        setstateName(item[0]);
      }
    });
  };

  //--Pagination onchange--//
  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <ToastContainer autoClose={2000} />
      <div className="header_title">BVI PolarisKey</div>
      <Divider />
      <div className="module_title my">Missing Dose</div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sm={12} className="label_row">
          <div className="search_label">Search by:</div>
        </Grid>
        <Grid item xs={12} md={2} sm={12}>
          <TextField
            className="form_textfield form_search_field"
            label="First name"
            type="text"
            variant="standard"
            name="firstName"
            value={firstNamesearch}
            onChange={(e) => setfirstNamesearch(e.target.value)}
            onKeyUp={SignInC}
            inputProps={{
              maxLength: 30,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={12}>
          <TextField
            className="form_textfield form_search_field"
            label="Last name"
            type="text"
            variant="standard"
            name="lastName"
            value={lastNamesearch}
            onChange={(e) => setlastNamesearch(e.target.value)}
            onKeyUp={SignInC}
            inputProps={{
              maxLength: 30,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className=" form-dropdown form_search_field"
              clearable
              onKeyUp={SignInC}
              value={searchDob}
              onChange={(date) => searchDobHandleChange(date)}
              format="MM/dd/yyyy"
              maxDate={new Date()}
              autoOk
              label={
                <Typography variant="inherit" component="span">
                  DOB <span className="adjs">(MM/DD/YYYY) </span>
                </Typography>
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3} sm={12} style={{ marginTop: "-4px" }}>
          <FormControl
            variant="standard"
            className="form-dropdown form_search_field"
          >
            <InputLabel>Country</InputLabel>
            <Select
              value={FilterCountry}
              name="FilterCountry"
              onChange={FilterCountryOnchange}
              title={countryName}
              required
            >
              <MenuItem style={{ height: "25px" }} value={""}> </MenuItem>
              {countryList.map((item) => (
                <MenuItem key={item.id} value={item.countryCode}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} sm={12} style={{ marginTop: "-4px" }}>
          <FormControl
            variant="standard"
            className="form-dropdown form_search_field"
          >
            <InputLabel>Location</InputLabel>
            <Select
              disabled={!stateSelect.length > 0}
              value={FilterState}
              name="FilterState"
              onChange={FilterStateChange}
              title={stateName}
              required
            >
              {stateSelect.map((item) => (
                <MenuItem value={item[1].stateCode}>
                  {item[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="filter_lebel">Filter by:</div>
      <div className="filter_row">
        <div style={{ width: "500px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12} style={{ marginTop: "-4px" }}>
              <FormControl
                variant="standard"
                className=" form-dropdown form_search_field form_status_field"
              >
                <InputLabel>Status</InputLabel>
                <Select value={values} displayEmpty onChange={handleChange}>
                  <MenuItem style={{ height: "25px" }} value={""}> </MenuItem>
                  <ListSubheader style={{ fontStyle: "italic" }}>- Review Statuses -</ListSubheader>
                  <MenuItem value={"Pending Review"}>Pending Review</MenuItem>
                  <MenuItem value={"Pending ID/Vax Verification"}>
                    Pending ID/Vax Verification
                  </MenuItem>
                  <ListSubheader style={{ fontStyle:"italic"}}>- Validation Statuses -</ListSubheader>
                  <MenuItem value={"Requires Clinic Verification"}>
                    Requires Clinic Verification
                  </MenuItem>
                  <MenuItem value={"Requires Vax Card Verification"}>Requires Vax Card Verification</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={6} sm={12} style={{ marginTop: "-4px" }}>
              <FormControl
                variant="standard"
                className=" form-dropdown form_search_field form_status_field"
              >
                <InputLabel>Traveler Category</InputLabel>
                <Select value={TravelCategory} displayEmpty onChange={travelCategoryOnChange}>
                  <MenuItem style={{ height: "25px" }} value={""}> </MenuItem>
                  <MenuItem value={"Traveler"}>Traveler</MenuItem>
                  <MenuItem value={"Public"}>
                    Public
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </div>
      </div>
      <div className="d-flex my">
        <div>
          <button className="srchbtn" onClick={SearchByValue}>
            Search
          </button>
        </div>
        <div className="mx"></div>
        <div>
          <button className="srchbtn" onClick={Resetbyvalue}>
            Reset
          </button>
        </div>
      </div>

      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : data.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className="table_head">
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "formSubmittedOn"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("formSubmittedOn")}
                  >
                    Submitted on (MM/DD/YYYY)
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "firstName"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("firstName")}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "lastName"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("lastName")}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "dob"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("dob")}
                  >
                    DOB (MM/DD/YYYY)
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  Status & Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((dataItems) => {
                return (
                  <Row
                    keyvalue={dataItems.missingDoseFormId}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                    handleExpandClick={handleExpandClick}
                    key={dataItems.missingDoseFormId}
                    dataItems={dataItems}
                    stateSelect={stateSelect}
                    countryList={countryList}
                    labName={labName}
                    getlist={getlist}
                    vaccineNames={vaccineNames}
                    setloader={setloader}
                  />
                );
              })}
            </TableBody>
          </Table>
          {totalcount > 10 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              colSpan={3}
              component="div"
              count={totalcount}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          ) : (
            ""
          )}
        </TableContainer>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} align="center">
            <Typography>No Records Found</Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default MissingDose;
