import React from "react";
import "./Header.css";
import Headerlogo from "../../Assets/Polaris Logo.svg";
import Headerlogo1 from "../../Assets/BVI_footer.png";

const Header = () => {
  return (
    <>
      <div className="header mt px">
        <div>
          <img src={Headerlogo} className="headerlogo" alt="headerLogo1" />
        </div>
        <div>
          <img src={Headerlogo1} className="headerlogo1" alt="headerLogo2" />
        </div>
      </div>
    </>
  );
};

export default Header;
