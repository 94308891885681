import { CardTravelSharp } from '@material-ui/icons'
import React from 'react'
import PDFLogo from './icons/PDFLogo.png'


const ImgSlide = ({ imgurl, handleOpen, indexval, slideIndex, data }) => {
  const getImageURL = (imgurl, slideIndex) => {
    data.map((item, index) => {
      if (index === slideIndex - 1) {
        handleOpen(item, slideIndex)
      }
    })
  }

  return (
    <div
      key={imgurl.id}
      className={slideIndex === indexval + 1 ? 'slide active-anim' : 'slide'}
    >
      <img
        onClick={() => getImageURL(imgurl.url, slideIndex)}
        src={
          imgurl.url.split('.').pop().split(/\#|\?/)[0].toLowerCase() === 'pdf'
            ? PDFLogo
            : imgurl.url
        }
      />{' '}
    </div>
  )
}

export default ImgSlide
