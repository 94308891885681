import React, { useEffect, useState } from "react";
import {
  IconButton,
} from "@mui/material";
import {
  BrowserRouter as Router,
  useHistory,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import NewPatient from "../NewPatient/TableMain";
import Form from "../AddPatient/Form";
import ExistingPatient from "../ExistingPatient/Table";
import MissingDose from "../MissingDose/Table";
import ApprovedPatient from "../ApprovedPatient/Table";
import navlogo from "../../Assets/Google-Pass.png";
import close from "../../Assets/close.png";
import { slide as Menu } from "react-burger-menu";
import NewPatientActiveIcon from "../../Assets/NewPatientActiveIcon.png";
import NewPatientInActiveIcon from "../../Assets/NewPatientInActiveIcon.png";
import ActiveIcon from "../../Assets/ActiveIcon.png";
import InactiveIcon from "../../Assets/InactiveIcon.png";
import missingActiveIcon from "../../Assets/MissingActiveIcon.png";
import missingInActiveIcon from "../../Assets/MissingInactiveIcon.png";
import ApproveActiveIcon from "../../Assets/ApproveActiveIcon.png";
import ApproveInactiveIcon from "../../Assets/ApproveInactiveIcon.png";
import AddPatientActive from "../../Assets/AddPatientActive.png";
import AddPatientInactive from "../../Assets/AddPatientInactive.png";
import FeedbackIcon from "../../Assets/FeedbackIcon.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; 
import "./SideNavbar.css";
import "./burger.css";

const SideNavbar = () => {
  useEffect(() => {
    if (localStorage.getItem("features") == null) {
      history.push("/");
      console.log("out");
    }

    if (window.location.pathname === "/new-patient") {
      setActivemenu1(true);
      setActivemenu2(false);
      setActivemenu3(false);
      setActivemenu4(false);
      setActivemenu5(false);
    } else if (window.location.pathname === "/existing-patient") {
      setActivemenu1(false);
      setActivemenu2(true);
      setActivemenu3(false);
      setActivemenu4(false);
      setActivemenu5(false);
    } else if (window.location.pathname === "/missing-dose") {
      setActivemenu1(false);
      setActivemenu2(false);
      setActivemenu3(true);
      setActivemenu4(false);
      setActivemenu5(false);
    }
    else if (window.location.pathname === "/approved-patient") {
      setActivemenu1(false);
      setActivemenu2(false);
      setActivemenu3(false);
      setActivemenu4(true);
      setActivemenu5(false);
    }
    else if (window.location.pathname === "/add-patient") {
      setActivemenu1(false);
      setActivemenu2(false);
      setActivemenu3(false);
      setActivemenu4(false);
      setActivemenu5(true);
    }
  }, [localStorage.getItem("features")]);

  const [changeValue, setchangeValue] = React.useState(
    localStorage.getItem("features")
  );
  const history = useHistory();

  const [isactivemenu1, setActivemenu1] = useState(true);
  const [isactivemenu2, setActivemenu2] = useState(false);
  const [isactivemenu3, setActivemenu3] = useState(false);
  const [isactivemenu4, setActivemenu4] = useState(false);
  const [isactivemenu5, setActivemenu5] = useState(false);
  const [getLogout, setLogout] = React.useState(false);

  const routes = [
    {
      path: "/new-patient",
      exact: true,
      main: () => (
        <>
          <NewPatient />
        </>
      ),
    },
    {
      path: "/existing-patient",
      exact: true,
      main: () => (
        <>
          <ExistingPatient />
        </>
      ),
    },

    {
      path: "/missing-dose",
      exact: true,
      main: () => (
        <>
          <MissingDose />
        </>
      ),
    },
    {
      path: "/approved-patient",
      exact: true,
      main: () => (
        <>
          <ApprovedPatient />
        </>
      ),
    },
    {
      path: "/add-patient",
      exact: true,
      main: () => (
        <>
          <Form />
        </>
      ),
    },
  ];

  const Logout = () => {
    setLogout(true);
  };

  const loadMenu1 = () => {
    setActivemenu1(true);
    setActivemenu2(false);
    setActivemenu3(false);
    setActivemenu4(false);
    setActivemenu5(false);
  }

  const loadMenu2 = () => {
    setActivemenu1(false);
    setActivemenu2(true);
    setActivemenu3(false);
    setActivemenu4(false);
    setActivemenu5(false);
  };

  const loadMenu3 = () => {
    setActivemenu1(false);
    setActivemenu2(false);
    setActivemenu3(true);
    setActivemenu4(false);
    setActivemenu5(false);
  };

  const loadMenu4 = () => {
    setActivemenu1(false);
    setActivemenu2(false);
    setActivemenu3(false);
    setActivemenu4(true);
    setActivemenu5(false);
  };

  const loadMenu5 = () => {
    setActivemenu1(false);
    setActivemenu2(false);
    setActivemenu3(false);
    setActivemenu4(false);
    setActivemenu5(true);
  };

  const Logout_req = () => {
    history.push({
      pathname: "/",
    });
    localStorage.clear();
  };
  const handleClose = () => {
    setLogout(false);
  };

  return (
    <>
      {getLogout ? (
        <div
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modalBox"
        >
          <div className="modal-content">
            <img src={close} className="closeIcn" onClick={handleClose} alt="modalClose" />
            <div className="d-flex">Are you sure you want to logout?</div>
            <div className="d-flex">
              <button className="modal_btn mr" onClick={Logout_req}>
                Yes
              </button>

              <button className="ml modal_btn " onClick={handleClose}>
                No
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="navbar_mobile_container">
        <div className="navbar_mobile_logo">
          <img src={navlogo} width="60px" alt="navLogo" />
        </div>
        <div className="navbar_mobile_menu">
          <Menu right>
            {changeValue && changeValue.includes("Approval Module(edit)") || changeValue && changeValue.includes("Approval Module(view)") ? (
              <Link to="/approved-patient" id="Approved_Patient">
                Approved Patient
              </Link>
            ) : (
              ""
            )}
            {changeValue && changeValue.includes("Vax Patient Module") ? (
              <Link to="/existing-patient" id="exist">
                Existing patient
              </Link>
            ) : (
              ""
            )}
            {changeValue && changeValue.includes("Vax Form Module") ? (
              <Link to="/new-patient" id="new">
                New patient
              </Link>
            ) : (
              ""
            )}
            {changeValue && changeValue.includes("Vax Missing Dose Module") ? (
              <Link to="/missing-dose" id="missing">
                Missing Dose
              </Link>
            ) : (
              ""
            )}
            {changeValue && changeValue.includes("Vax Add Patient") ? (
              <Link to="/add-patient" id="addPatient">
                Add Patient
              </Link>
            ) : (
              ""
            )}

            <Link to="/" id="about">
              Logout
            </Link>
          </Menu>
        </div>
      </div>

      <Router>
        <div className="navbar_container">
          <div className="navbar_main_left">
            <div className="dropdown">
              <img src={navlogo} width="60px" alt="navLogo" />
            </div>
            <div className="navbar_menu_icons">
              {changeValue && changeValue.includes("Approval Module(edit)") || changeValue && changeValue.includes("Approval Module(view)") ? (
                <NavLink
                  to="/approved-patient"
                  exact
                  onClick={loadMenu4}
                >
                  {isactivemenu4 === true ? (
                    <img
                      src={ApproveActiveIcon}
                      alt="Approved_Patient"
                      title="Approved Patient"
                    />
                  ) : (
                    <img
                      src={ApproveInactiveIcon}
                      alt="Approved_Patient"
                      title="Approved Patient"
                    />
                  )}
                </NavLink>
              ) : (
                ""
              )}


              {changeValue && changeValue.includes("Vax Patient Module") ? (
                <NavLink to="/existing-patient" exact onClick={loadMenu2}>
                  {isactivemenu2 === true ? (
                    <img
                      src={ActiveIcon}
                      alt="Existing"
                      title="Existing patient approval"
                    />
                  ) : (
                    <img
                      src={InactiveIcon}
                      alt="Existing"
                      title="Existing patient approval"
                    />
                  )}
                </NavLink>
              ) : (
                ""
              )}

              {changeValue && changeValue.includes("Vax Form Module") ? (
                <NavLink to="/new-patient" exact onClick={loadMenu1}>
                  {isactivemenu1 === true ? (
                    <img
                      src={NewPatientActiveIcon}
                      alt="New"
                      title="New patient approval"
                    />
                  ) : (
                    <img
                      src={NewPatientInActiveIcon}
                      alt="New"
                      title="New patient approval"
                    />
                  )}
                </NavLink>
              ) : (
                ""
              )}

              {changeValue &&
                changeValue.includes("Vax Missing Dose Module") ? (
                <NavLink
                  to="/missing-dose"
                  exact
                  onClick={loadMenu3}
                >
                  {isactivemenu3 === true ? (
                    <img
                      src={missingActiveIcon}
                      alt="Missing"
                      title="Missing Dose"
                    />
                  ) : (
                    <img
                      src={missingInActiveIcon}
                      alt="Missing"
                      title="Missing Dose"
                    />
                  )}
                </NavLink>
              ) : (
                ""
              )}

              {changeValue &&
                changeValue.includes("Vax Add Patient") ? (
                <NavLink
                  to="/add-patient"
                  exact
                  onClick={loadMenu5}
                >
                  {isactivemenu5 === true ? (
                    <img
                      src={AddPatientActive}
                      alt="Add Patient"
                      title="Add Patient"
                    />
                  ) : (
                    <img
                      src={AddPatientInactive}
                      alt="Add Patient"
                      title="Add Patient"
                    />
                  )}
                </NavLink>
              ) : (
                ""
              )}

              <div className="mt">
                <IconButton
                  aria-label="logout"
                  style={{ color: "#FFF" }}
                  onClick={Logout}
                  disableRipple={true}
                  disableFocusRipple={true}
                >
                  <ExitToAppIcon style={{ width: "1.3em", height: "1.2em" }} />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="navbar_main_right">
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </div>
        </div>
        <img className="feedback" id="myCustomTrigger" onClick={()=>window.showCollectorDialog()} src={FeedbackIcon}/>
      </Router>
    </>
  );
};

export default SideNavbar;
