import React from "react";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";

function TableHeader({ handleSort, orderBy, order, setOrder }) {
  const createSortHandler = (id) => {
    handleSort(id);
    setOrder(order === "asc" ? "desc" : "asc");
  };
  return (
    <TableHead className="table_head">
      <TableRow>
        <TableCell align="center"></TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "formSubmittedOn"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("formSubmittedOn")}
          >
            Submitted on (MM/DD/YYYY)
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "firstName"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("firstName")}
          >
            First Name
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "lastName"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("lastName")}
          >
            Last Name
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "dob"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("dob")}
          >
            DOB (MM/DD/YYYY)
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          Status & Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
