import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./Components/Auth/Login";
import LoginOTP from "./Components/Auth/LoginOTP";
import SideNavBar from "./Components/SideNavbar/SideNavbar";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router";
import Footer from './Components/Footer/Footer';
import FeedbackIcon from "../src/Assets/FeedbackIcon.png";

function App() {
  let history = useHistory();

  //States
  const [sessionTimeOut, setsessionTimeOut] = useState(false);

  if (
    localStorage.getItem("accessToken") !== null &&
    (window.location.pathname === "/" ||
      window.location.pathname === "/login-otp")
  ) {
    if (localStorage.getItem("features").indexOf("Vax Form Module") >= 0) {
      window.location.replace("/new-patient");
    }
    else if (localStorage.getItem("features") === "Vax Patient Module") {
      window.location.replace("/existing-patient");
    } else {
      window.location.replace("/existing-patient");
    }

  }

  useEffect(() => {
    if (sessionTimeOut === true) {
      localStorage.clear();
      window.location.replace("/");
    }
  }, [sessionTimeOut]);
  const handleOnIdle = (event) => {
    if (event === undefined) {
      setsessionTimeOut(true);
    }
  };

  const handleOnActive = (event) => { };

  const handleOnAction = (e) => {
    if (e !== undefined) {
      setsessionTimeOut(false);
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <>
      <div className="main">
        <Router>
          <Route path="/" exact component={Login} />
          <Route path="/login-otp" exact component={LoginOTP} />
          <Route path="/new-patient" exact component={SideNavBar} />
          <Route path="/existing-patient" exact component={SideNavBar} />
          <Route path="/missing-dose" exact component={SideNavBar} />
          <Route path="/approved-patient" exact component={SideNavBar} />
          <Route path="/add-patient" exact component={SideNavBar} />
        </Router>
        <img className="feedback" id="myCustomTrigger" onClick={()=>window.showCollectorDialog()} src={FeedbackIcon}/>
        <Footer />
      </div>
    </>
  );
}

export default App;
