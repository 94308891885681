import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import Header from "../Header/Header";
import Loginbg from "../../Assets/bg_image.png";
import "./Login.css";
import { useHistory } from "react-router-dom";
import * as API from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const history = useHistory();

  //Login form States
  const [Email, setEmail] = useState("");
  const [loader, setloader] = useState(false);

  //Login Form Error States
  const [EmailError, setEmailError] = useState(false);
  const [EmailValidateError, setEmailValidateError] = useState(false);

  //OnClick Functionalities
  const submit = (event) => {
    if (Email.trim() === "" && Email.trim().length === 0) {
      setEmailError(true);
      setEmailValidateError(false);
      return 0;
    } else if (
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(Email.trim()) &&
      Email.trim() !== ""
    ) {
      setEmailError(false);
      setEmailValidateError(true);
    } else {
      setloader(true);
      setEmailError(false);
      setEmailValidateError(false);
      let data = {
        email: Email.trim(),
      };

      console.log("=====API CALL=====");
      API.LoginAPI(data, (res) => {
        if (res.statusCode == "200") {
          data.from = "login";
          history.push({
            pathname: "/login-otp",
            state: { ...data, res },
          });
        } else {
          setloader(false);
          toast.error(" Email address has not been registered");
        }
      });
    }
  };

  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      submit();
    }
  };

  return (
    <>
      <Header />
      <ToastContainer autoClose={2000} />
      <div className="login-container">
        {loader == true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <Grid container alignItems="center" justify="center">
            <Grid item xs={12} md={6} sm={12} className="d-flex">
              <img src={Loginbg} alt="loginBg" className=" mx loginBg" />
            </Grid>
            <Grid item xs={12} md={6} sm={12} className="login-main">
              <Typography className="mb" variant="h5" style={{ width: "85%" }}>
                BVI-HSA Immunization Review Login
              </Typography>
              <div className="form_back">
                <div className="form_overlay">
                  <Grid container className="px py" style={{ height: "25vh" }}>
                    <Grid item xs={12} md={12} sm={12}>
                      <TextField
                        className="form_textfield login_textfield"
                        fullWidth
                        id="outlined-basic"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        variant="outlined"
                        label="Email Id"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={SignInC}
                      />
                      {EmailError ? (
                        <FormHelperText className="errorText">
                          Please enter the email address
                        </FormHelperText>
                      ) : null}
                      {EmailValidateError ? (
                        <FormHelperText className="errorText">
                          Please enter the Valid email address
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="d-flex">
                      <button className="btn1" onClick={submit}>
                        Next
                      </button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default Login;
